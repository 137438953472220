import firebase from 'firebase';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { useUsers } from 'hooks/use-users';

import 'styles/selectivity-react-custom.css';

function InviteUserModal(props) {

    const usersCxt = useUsers();
    const [showAlert, setShowAlert] = useState(false);
    const [alertVariant, setAlertVariant] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [validated, setValidated] = useState(false);

    const onChangeEmail = e => usersCxt.setUserProp({ email: e.target.value });
    const resetAlert = () => {
        setAlertVariant('');
        setAlertMessage('');
        setShowAlert(false);        
    }
    const handleSubmit = event => {

        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }
        usersCxt.saveInvite()
            .then(response => {
                window.setTimeout(()=>{
                    resetAlert();
                    props.onHide(true);
                }, 2000);
                setAlertVariant('success');
                setAlertMessage('User invitation sent successfully');
                setShowAlert(true);

                // usersCxt.sendInvite().then(rsp => {
                //     setAlertVariant('success');
                //     setAlertMessage('User invitation sent successfully');
                //     setShowAlert(true);
                // })
            })
            .catch((err) => {
                setAlertVariant('danger');
                setAlertMessage(err.code + ' ' + err.message);
                setShowAlert(true);
            })
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton={false}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Invite User
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showAlert && <Row>
                        <Col size={12}>
                            <Alert show={showAlert} variant={alertVariant} onClose={() => setShowAlert(false)} dismissible={false}>
                                <p>
                                    {alertMessage}
                                </p>
                            </Alert>
                        </Col>
                    </Row>}
                    <Form.Group controlId="fgEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control required type="email" placeholder="Email" value={usersCxt.user.email} onChange={onChangeEmail} />
                    </Form.Group>
                </Modal.Body>
                {!showAlert && <Modal.Footer>
                    <Button onClick={props.onHide} variant="secondary">Cancel</Button>
                    <Button type="submit">Send Invitation</Button>
                </Modal.Footer>}
            </Form>
        </Modal >
    )
}

export default InviteUserModal;