import React, { useEffect, useState } from 'react';
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

import { useFirebase } from 'hooks/use-firebase';
import { ProvideAuth, useAuth } from "hooks/use-auth.js";
import Spinner from 'components/Spinner';
import PublicLayout from "views/_Layouts/PublicLayout";
import PrivateLayout from "views/_Layouts/PrivateLayout";

import './styles.scss';

const App = () => {
  const auth = useAuth();
  return (
    auth.isPending ? <Spinner /> :
      (auth.user ? <PrivateLayout /> : <PublicLayout />)
  );
}

const AppWithProviders = () => {
  const fireContext = useFirebase();
  const { fireLoaded } = fireContext;  
  const appHistory = createBrowserHistory();

  // useEffect(() => {
  //   fetch('/__/firebase/init.json').then(async response => {
  //     firebase.initializeApp(await response.json());
  //     setFBInited(true);
  //   });
  // }, []);

  return (
    !fireLoaded ? <Spinner /> :
      <Router history={appHistory}>
        <ProvideAuth>
          <App />
        </ProvideAuth>
      </Router>
  );
}

/*
function App() {
  return (
    <ProvideAuth>
      <Router>
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/signup" component={Signup} />
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/forms" component={Forms} />
        <PrivateRoute path="/responses" component={Responses} />
        <PrivateRoute path="/reports" component={Reports} />
        <PrivateRoute path="/users" component={Users} />
        <PrivateRoute path="/teams" component={Teams} />
        <PrivateRoute path="/account" component={Account} />
      </Router>
    </ProvideAuth>
  );
}
*/

// function Login() {
//   return (
//     <div>
//       <h2>Login</h2>
//     </div>
//   );
// }

// function Signup() {
//   return (
//     <div>
//       <h2>Signup</h2>
//     </div>
//   );
// }

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
}

function Forms() {
  return (
    <div>
      <h2>Forms</h2>
    </div>
  );
}

function Responses() {
  return (
    <div>
      <h2>Responses</h2>
    </div>
  );
}

function Reports() {
  return (
    <div>
      <h2>Reports</h2>
    </div>
  );
}

function Users() {
  return (
    <div>
      <h2>Users</h2>
    </div>
  );
}

function Teams() {
  return (
    <div>
      <h2>Teams</h2>
    </div>
  );
}

function Account() {
  return (
    <div>
      <h2>Account</h2>
    </div>
  );
}

export default AppWithProviders;