import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Tabs, Tab, Table, Row, Col, Alert, Dropdown, Form, Button } from 'react-bootstrap';
import Selectivity from 'selectivity/react';
import Toggle from 'react-toggle';
//import { SwatchesPicker } from 'react-color';
import { Save, Copy, Trash2, ArrowLeft } from "react-feather";

import Utils from 'hooks/utils';
import useSessionStorage from "hooks/session-storage.js";
import { ProvideQstnr, useQstnr } from "hooks/use-qstnr.js";
import { CustomDropdownToggle } from "components/CustomDropdownToggle";
//import { GradientPicker } from "components/GradientPicker";
import { BGPicker, BGInput } from "components/BGPicker";

import 'styles/selectivity-react-custom.css';
import "react-toggle/style.css";
import './Settings.scss';
import 'components/BGPicker.scss';

function TabActionsBar() {
    let history = useHistory();
    const qstnrCxt = useQstnr();
    const onClickBack = e => history.push(`/workspaces/${qstnrCxt.settings.workspaceId}/qstnairs`);
    const onClickSave = e => qstnrCxt.saveQstnrDoc();
    return (
        <div className="float-right m-1 d-flex">
            <Button variant="secondary" onClick={onClickBack} className="mr-2"><ArrowLeft /> Back To QstnAirs</Button>
            <Button onClick={onClickSave} className="mr-2"><Save /> Save Changes</Button>
            <Dropdown className="dd-primary">
                <Dropdown.Toggle as={CustomDropdownToggle} />
                <Dropdown.Menu size="sm" title="" className="custom-dd-menu">
                    <Dropdown.Item><Copy size={16} /> Copy To New1</Dropdown.Item>
                    <Dropdown.Item><Trash2 size={16} /> Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

function BottomBar(props) {
    let history = useHistory();
    const qstnrCxt = useQstnr();
    const onClickBack = e => history.push(`/workspaces/${qstnrCxt.settings.workspaceId}/qstnairs`);
    return (
        <>
            <Form.Group>
                <Row>
                    <Col className="col-12 text-center">
                        <Alert show={props.showAlert} key={1} variant={props.alertVariant}>
                            {props.alertMessage}
                        </Alert>
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col xs={6}>
                        <Button variant="secondary" onClick={onClickBack}><ArrowLeft /> Back To QstnAirs</Button>
                    </Col>
                    <Col xs={6} className="text-right">
                        <Button type="submit"><Save /> Save Changes</Button>
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
}

function Basics() {
    const qstnrCxt = useQstnr();
    const [validated, setValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertVariant, setAlertVariant] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            setAlertVariant('danger');
            setAlertMessage(`Please fill in the required fields`);
            setShowAlert(true);
        } else {
            qstnrCxt.saveQstnrDoc()
                .then(() => {
                    setAlertVariant('success');
                    setAlertMessage('Changes saved successfully');
                    setShowAlert(true);
                })
                .catch((err) => {
                    setAlertVariant('danger');
                    setAlertMessage(err.code + ' ' + err.message);
                    setShowAlert(true);
                })
        }
    }
    const onChangeTitle = e => qstnrCxt.setSettingsProp({ title: e.target.value });
    const onChangeDesc = e => qstnrCxt.setSettingsProp({ description: e.target.value });
    const onChangeDocCreators = e => qstnrCxt.setSettingsProp({ docCreators: e.value });
    const onChangeDefaultLang = e => qstnrCxt.setSettingsProp({ defaultLang: e.value });
    const onChangeMultilingual = e => qstnrCxt.setSettingsProp({ isMultilingual: e.target.checked });
    const onChangeLanguages = e => {
        if (e.removed) {
            console.warn('All existing translations will be removed. Do you want to continue?');
        }
        console.log(e.value);
        qstnrCxt.setSettingsProp({ languages: e.value });
        qstnrCxt.setTranslationLanguages(e.value);
    }

    useEffect(() => {
        if (showAlert) {
            window.setTimeout(() => {
                setShowAlert(false);
                if (alertVariant === 'success') {
                    setValidated(false);
                }
            }, 4000);
        }
    }, [showAlert]);

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="mt-3">

            <Form.Group controlId="fgTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control required type="text" placeholder="Title" value={qstnrCxt.settings.title} onChange={onChangeTitle} maxLength={100} />
            </Form.Group>

            <Form.Group controlId="fgDescription">
                <Form.Label>Short description</Form.Label>
                <Form.Control as="textarea" required rows="3" placeholder="Short Description" value={qstnrCxt.settings.description} onChange={onChangeDesc} maxLength={1000} />
            </Form.Group>

            <Form.Group controlId="fgDocCreators">
                <Form.Label>Who can submit responses?</Form.Label>
                <Selectivity.React
                    allowClear={false}
                    items={[
                        { id: "public", text: "Anyone (No signing-in required)" },
                        { id: "signedin", text: "Any signed-in user" },
                        { id: "org", text: "All Organization users" },
                        { id: "workspace", text: "All Workspace users" },
                        { id: "me", text: "Only me" },
                    ]}
                    value={qstnrCxt.settings.docCreators}
                    onChange={onChangeDocCreators}
                    placeholder='Document Creators'
                    className="sr-wrapper"
                />
            </Form.Group>

            <Form.Group controlId="fgDefaultLanguage">
                <Form.Label>Default Language</Form.Label>
                <Selectivity.React
                    items={Utils.getLanguages()}
                    placeholder='Language'
                    className="sr-wrapper"
                    value={qstnrCxt.settings.defaultLang}
                    onChange={onChangeDefaultLang}
                />
            </Form.Group>

            <Form.Group controlId="fgMultilanguage">
                <Row>
                    <Col xs={12}>
                        <Form.Label>Enable Multilanguage?</Form.Label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Toggle checked={qstnrCxt.settings.isMultilingual}
                            aria-label='Enable Multilanguage?'
                            onChange={onChangeMultilingual} />
                    </Col>
                </Row>
            </Form.Group>

            {qstnrCxt.settings.isMultilingual && <Form.Group controlId="fgLanguages">
                <Form.Label>Languages</Form.Label>
                <Selectivity.React
                    allowClear={true}
                    multiple={true}
                    items={Utils.getLanguages()}
                    placeholder='Languages'
                    className="sr-wrapper"
                    value={qstnrCxt.settings.languages}
                    onChange={onChangeLanguages}
                />
            </Form.Group>}

            <BottomBar showAlert={showAlert} alertVariant={alertVariant} alertMessage={alertMessage} />

            {/* <Form.Group>
                <Row>
                    <Col className="col-12 text-center">
                        <Alert show={showAlert} key={1} variant={alertVariant}>
                            {alertMessage}
                        </Alert>
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col xs={6}>
                        <Button variant="secondary" onClick={onClickBack}><ArrowLeft /> Back To QstnAirs</Button>
                    </Col>
                    <Col xs={6} className="text-right">
                        <Button type="submit"><Save /> Save Changes</Button>
                    </Col>
                </Row>
            </Form.Group> */}

        </Form>
    )
}

function Design() {
    const qstnrCxt = useQstnr();
    const [validated, setValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertVariant, setAlertVariant] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const onChangeCustomBG = e => qstnrCxt.setSettingsProp({ isCustomBG: e.target.checked });

    const handleClick = () => {
        setShowPicker(!showPicker);
    };

    const onSelectBG = (bgType, bgObject) => {
        qstnrCxt.setSettingsBGProps(bgType, bgObject);
        window.setTimeout(() => setShowPicker(false), 250);
    }

    const handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            setAlertVariant('danger');
            setAlertMessage(`Please fill in the required fields`);
            setShowAlert(true);
        } else {
            qstnrCxt.saveQstnrDoc()
                .then(() => {
                    setAlertVariant('success');
                    setAlertMessage('Changes saved successfully');
                    setShowAlert(true);
                })
                .catch((err) => {
                    setAlertVariant('danger');
                    setAlertMessage(err.code + ' ' + err.message);
                    setShowAlert(true);
                })
        }
    }

    useEffect(() => {
        if (showAlert) {
            window.setTimeout(() => {
                setShowAlert(false);
                if (alertVariant === 'success') {
                    setValidated(false);
                }
            }, 4000);
        }
    }, [showAlert]);

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="mt-3">

            <Form.Group>
                <Row>
                    <Col xs={6}>
                        Set a custom background for the QstnAir?
                    </Col>
                    <Col xs={6} className="text-right">
                        <Toggle checked={qstnrCxt.settings.isCustomBG}
                            aria-label='Set custom background?'
                            onChange={onChangeCustomBG} />
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col xs={6}>
                        Select a custom background
                    </Col>
                    <Col xs={6} className="text-right">
                        <BGInput bgType={qstnrCxt.settings.bgType} bgObject={qstnrCxt.settings.bgObject} onClick={handleClick} />
                        {/* <div className="bg-select-wrapper" onClick={handleClick}>
                            <div className="bg-selected" style={getBGStyle()} />
                        </div> */}
                    </Col>
                </Row>
                {showPicker && <BGPicker bgType={qstnrCxt.settings.bgType} bgObject={qstnrCxt.settings.bgObject} onSelectBG={onSelectBG} />}
            </Form.Group>

            <BottomBar showAlert={showAlert} alertVariant={alertVariant} alertMessage={alertMessage} />
        </Form>
    );
}

function Notifications() {
    let history = useHistory();
    const qstnrCxt = useQstnr();
    const onChangeNotifyEditors = e => qstnrCxt.setSettingsProp({ notifyEditors: e.target.checked });
    const onChangeNotifyRespondent = e => qstnrCxt.setSettingsProp({ notifyRespondent: e.target.checked });

    const [validated, setValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertVariant, setAlertVariant] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            qstnrCxt.saveQstnrDoc()
                .then(() => {
                    setAlertVariant('success');
                    setAlertMessage('Changes saved successfully');
                    setShowAlert(true);
                })
                .catch((err) => {
                    setAlertVariant('danger');
                    setAlertMessage(err.code + ' ' + err.message);
                    setShowAlert(true);
                })
        }
    }
    const onClickBack = e => history.push(`/workspaces/${qstnrCxt.settings.workspaceId}/qstnairs`);

    useEffect(() => {
        if (showAlert) {
            window.setTimeout(() => {
                setShowAlert(false);
                setValidated(false);
            }, 4000);
        }
    }, [showAlert]);

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="mt-3">
            <Form.Group>
                <Row>
                    <Col xs={11}>
                        Receive email when someone submits a new responses?
                </Col>
                    <Col xs={1} className="text-right">
                        <Toggle
                            checked={qstnrCxt.settings.notifyEditors}
                            aria-label='Receive email when someone submits a new responses?'
                            onChange={onChangeNotifyEditors} />
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                <Row>
                    <Col xs={11}>
                        Send email to respondent on submission?
                </Col>
                    <Col xs={1} className="text-right">
                        <Toggle
                            checked={qstnrCxt.settings.notifyRespondent}
                            aria-label='Send email to respondent on submission?'
                            onChange={onChangeNotifyRespondent} />
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                <Row>
                    <Col className="col-12 text-center">
                        <Alert show={showAlert} key={1} variant={alertVariant}>
                            {alertMessage}
                        </Alert>
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                <Row>
                    <Col xs={6}>
                        <Button variant="secondary" onClick={onClickBack}><ArrowLeft /> Back To QstnAirs</Button>
                    </Col>
                    <Col xs={6} className="text-right">
                        <Button type="submit"><Save /> Save Changes</Button>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
    )
}
function Translations() {
    //const [translations, setTranslations] = useState({});
    const qstnrCxt = useQstnr();
    const defaultLang = qstnrCxt.settings.defaultLang;
    const defaultLangText = (Utils.getLanguages(defaultLang))[0].text;
    const languages = qstnrCxt.settings.languages;
    //const languagesText = Utils.getLanguages(languages).map(item => item.text);
    const allLanguages = Utils.getLanguages();
    const translations = qstnrCxt.settings.translations;
    console.log(translations);
    // if(!translations) {
    //     translations = {};
    // }
    // languages && languages.forEach(lang => {
    //     if(!translations[lang]) {
    //         translations[lang] = {};
    //     }
    // })
    const fields = qstnrCxt.settings.fields;

    const onChangeFieldTitleX = e => {
        var arr = e.target.name.split('-');
        qstnrCxt.setTranslationKeyValue(arr[1], arr[2], e.target.value);
    };

    return (
        <>
            {qstnrCxt.settings.isMultilingual && <div>
                {Utils.getLanguages(languages).map((item) => <li key={item.id}>{item.text}</li>)}
            </div>}

            {qstnrCxt.settings.fields.forEach(elem => <th key={elem.id}>{elem.title}</th>)}

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{defaultLangText}</th>
                        {
                            Object.keys(translations).map(lang =>
                                <th key={lang}>{allLanguages.find(item => item.id === lang).name}</th>
                            )
                        }
                        {/* {languages && languagesText.map(lang => <th key={lang}>{lang}</th>)} */}
                    </tr>
                </thead>
                <tbody>
                    {
                        fields && fields.map((field, indx) =>
                            <React.Fragment key={'rf' + field.id}>
                                <tr className="field" key={field.id}>
                                    <td>Field {indx + 1}</td>
                                    <td>{field.title}</td>
                                    {
                                        Object.keys(translations).map(lang =>
                                            <td key={'td' + lang + field.id}>
                                                <input type="text" name={'fld-' + lang + '-' + field.id} value={translations[lang][field.id]} onChange={onChangeFieldTitleX}></input>
                                            </td>
                                        )
                                    }
                                </tr>
                                {
                                    field.branches && field.branches.map(branch =>
                                        <tr className="branch" key={branch.id}>
                                            <td>Field Branch</td>
                                            <td>{branch.value}</td>
                                            {
                                                Object.keys(translations).map(lang =>
                                                    <td key={'td' + lang + branch.id}>
                                                        <input type="text" name={'bch-' + lang + '-' + branch.id} value={translations[lang][branch.id]} onChange={onChangeFieldTitleX}></input>
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    )
                                }
                                {
                                    field.choices && field.choices.map(choice =>
                                        <tr className="choice" key={choice.id}>
                                            <td>Field Choice</td>
                                            <td>{choice.text}</td>
                                            {
                                                Object.keys(translations).map(lang =>
                                                    <td key={'td' + lang + choice.id}>
                                                        <input type="text" name={'chc-' + lang + '-' + choice.id} value={translations[lang][choice.id]} onChange={onChangeFieldTitleX}></input>
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    )
                                }
                            </React.Fragment>
                        )
                    }
                </tbody>
            </Table>
        </>
    )
}

function Publish(props) {
    return (
        <>
            <h1>Share & Publish</h1>
            <div>Publish immediately</div>
            <div>Schedule a Start Time?</div>
            <div>Start Time?</div>
            <div>Schedule an End Time?</div>
            <div>End Time?</div>
            <div>Share on FB / Twitter / etc.</div>
        </>
    )
}

function Settings(props) {

    let history = useHistory();
    const qstnrCxt = useQstnr();
    const qstnrId = props.match.params.qstnrId;
    const [gradients, setGradients] = useSessionStorage("ss_grdnts", "");

    useEffect(() => {

        // const loadGradientsJson = () => {
        //     if (!gradients) {
        //         console.log('fetching...')
        //         fetch('/assets/js/gradients.json')
        //             .then(function (response) {
        //                 console.log(response)
        //                 return response.json();
        //             })
        //             .then(function (jsonData) {
        //                 console.log(jsonData);
        //                 setGradients(jsonData);
        //             });
        //     } else {
        //         console.log('already fetched.')
        //     }
        // }

        qstnrCxt.loadQstnrDoc(qstnrId);
        //loadGradientsJson();
    }, []);

    const onClickEditFields = e => {
        history.push(`/qstnairs/${qstnrId}/studio`);
    }

    const onClickPreview = e => {
        history.push(`/qstnairs/${qstnrId}/preview`);
    }

    const onClickPublish = e => {

    }

    return (
        <>
            <div className="header mx-4">
                <div className="container-fluid">
                    <div className="header-body">
                        {/* <div className="row text-center">
                            <div className="col-10 offset-1">
                                <button onClick={onClickEditFields} className="btn btn-sm btn-link navi">Edit Questions</button>
                                <button onClick={onClickPreview} className="btn btn-sm btn-link mr-2 navi">Preview QstnAir</button>
                                <button onClick={onClickPublish} className="btn btn-sm btn-link navi">Publish QstnAir</button>
                            </div>
                        </div> */}
                        <div className="row align-items-end">
                            <div className="col">
                                <h5 className="header-pretitle">{qstnrCxt.settings ? qstnrCxt.settings.title : ''}</h5>
                                <h1 className="header-title"> QstnAir Settings</h1>


                                {/* <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">Home</li>
                                        <li className="breadcrumb-item"><Link to={"/workspaces"}>Workspaces</Link></li>
                                        <li className="breadcrumb-item">
                                            <Link to={`/workspaces/${qstnrCxt.settings.workspaceId}/qstnairs`}>QstnAirs</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="#" onClick={e => e.preventDefault()}>
                                                {qstnrCxt.settings ? qstnrCxt.settings.title : ''}
                                            </a>
                                        </li>
                                    </ol>
                                </nav> */}

                            </div>
                            <div className="col-4 float-right">
                                {/* <TabActionsBar /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid">

                <Row>
                    <Col md={{ span: 12 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
                        <Tabs defaultActiveKey="basics" id="uncontrolled-tab-example">
                            <Tab eventKey="basics" title="Basics">
                                <Basics />
                            </Tab>
                            <Tab eventKey="design" title="Design">
                                <Design />
                            </Tab>
                            <Tab eventKey="notifications" title="Notifications">
                                <Notifications />
                            </Tab>
                            <Tab eventKey="translations" title="Translations" disabled={!qstnrCxt.settings.isMultilingual}>
                                <Translations />
                            </Tab>
                            <Tab eventKey="publish" title="Publish">
                                <Publish />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </div>
        </>
    )
}

function SettingsHOC(props) {
    return (
        <ProvideQstnr>
            <Settings {...props} />
        </ProvideQstnr>
    )
}

export default SettingsHOC;