import React, { useState } from 'react';
import { Link, useLocation, useHistory } from "react-router-dom";
import { Row, Col, Form, Alert } from 'react-bootstrap';
import queryString from 'query-string';

import { useAuth } from 'hooks/use-auth';

function Login() {

    const auth = useAuth();
    let location = useLocation();
    let history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [validated, setValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertVariant, setAlertVariant] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const handleError = err => {
        setAlertVariant('danger');
        setAlertMessage(err.message);
        setShowAlert(true);
    }

    const authRedirect = up => {
        console.log('login redirect');
        var qsParsed = queryString.parse(location.search);
        if ('to' in qsParsed) {
            history.push(qsParsed['to']);
        }
        else {
            history.push('/workspaces');
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            auth.signin(email, password)
            .then(usr => {
                auth.getUserProfile(usr)
                    .then(authRedirect)
                    .catch(handleError)
            })
            .catch(handleError);
        }
    }

    return (
        <Row className="row justify-content-center">
            <Col xl={4} lg={6} md={8} xs={12} className="my-5">
                <h1 className="display-4 text-center mb-3">
                    Login
                </h1>
                <p className="text-muted text-center mb-5">
                    To Access QstnAir
                </p>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control required type="email" placeholder="name@address.com"
                            onChange={e => setEmail(e.target.value)}></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control required type="password" placeholder="Enter your password"
                            onChange={e => setPassword(e.target.value)}></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Alert show={showAlert} variant={alertVariant} onClose={() => setShowAlert(false)}>
                            {alertMessage}
                        </Alert>
                    </Form.Group>
                    <button className="btn btn-lg btn-block btn-primary mb-3" type="submit">
                        Login
                    </button>
                    <div className="text-center">
                        <small className="text-muted text-center">
                            Don't have an account yet? <Link to="/signup">Sign Up</Link>
                        </small>
                    </div>
                    <div className="text-center">
                        <small className="text-muted text-center">
                            <Link to="/reset">Forgot password?</Link>
                        </small>
                    </div>
                </Form>
            </Col>
        </Row>
    )
}

export default Login;