import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Selectivity from 'selectivity/react';

import Utils from 'hooks/utils';
import { useQstnr } from 'hooks/use-qstnr.js';


function NewQstnrModal(props) {

    let history = useHistory();
    let { workspaceId } = useParams();

    const [validated, setValidated] = useState(false);
    const qstnrCxt = useQstnr();

    const onChangeTitle = e => qstnrCxt.setSettingsProp({ title: e.target.value });
    const onChangeDefaultLang = e => qstnrCxt.setSettingsProp({ defaultLang: e.value });

    const handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            qstnrCxt.createQstnrDoc(workspaceId).then(docRef => {
                history.push(`/qstnairs/${docRef.id}/studio`);
            });
        }
    }

    useEffect(() => {
        function onInit() {
            qstnrCxt.resetQstnrDoc();
        }
        onInit();
    }, []);

    return (

        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create New QstnAir
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="fgTitle">
                        <Form.Label>Title</Form.Label>
                        <Form.Control required type="text" placeholder="Title" value={qstnrCxt.settings.title} onChange={onChangeTitle} maxLength={100} />
                    </Form.Group>
                    <Form.Group controlId="fgDefaultLanguage">
                        <Form.Label>Language</Form.Label>
                        <Selectivity.React
                            items={Utils.getLanguages()}
                            placeholder='Language'
                            className="sr-wrapper"
                            value={qstnrCxt.settings.defaultLang}
                            onChange={onChangeDefaultLang}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide} variant="secondary">Cancel</Button>
                    <Button type="submit">Create</Button>
                </Modal.Footer>
            </Form>
        </Modal >

    );
}

export default NewQstnrModal;