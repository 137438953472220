import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from "react-router-dom";
import { Row, Col, Form, Alert } from 'react-bootstrap';
import { Eye } from 'react-feather';
import queryString from 'query-string';

import { useAuth } from 'hooks/use-auth';

import './index.scss';

function Signup() {

    const auth = useAuth();
    let location = useLocation();
    let history = useHistory();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [pwdType, setPwdType] = useState('password');

    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [alertVariant, setAlertVariant] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const togglePwdType = () => {
        setPwdType(pwdType == 'password' ? 'input' : 'password');
    }

    const isStrongPassword = pass => {
        if (!pass || pass.length <= 8) {
            return false;
        }
        var variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        }

        var variationCount = 0;
        for (var check in variations) {
            variationCount += (variations[check] == true) ? 1 : 0;
        }
        return variationCount === 4;
    };

    const handleError = err => {
        setAlertVariant('danger');
        setAlertMessage(err.message);
        setShowAlert(true);
    }

    const authRedirect = ({user, wsid}) => {
        console.log('signup redirect');
        auth.getUserProfile(user)
        .then(up => {
            var qsParsed = queryString.parse(location.search);
            if ('to' in qsParsed) {
                history.push(qsParsed['to']);
            }
            else {
                history.push(`/workspaces/${wsid}/qstnairs`);
            }
        })
        .catch(handleError);
    }

    const handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            console.log(name, email, password);
            if (!isStrongPassword(password)) {
                setErrors({ ...errors, password: true })
                handleError({ message: 'Insufficient password complexity - (Lower, Upper, Digit and Non-Word)' });
                setValidated(true);
                return;
            }
            auth.signup(name, email, password)
                .then(authRedirect)
                .catch(handleError);
        }

    }

    useEffect(() => {
        if (showAlert) {
            window.setTimeout(() => setShowAlert(false), 4000);
        }
    }, [showAlert]);

    return (
        <Row className="justify-content-center signup">
            <Col xl={4} lg={6} md={8} xs={12} className="my-5">
                <h1 className="display-4 text-center mb-3">
                    Sign up
                </h1>
                <p className="text-muted text-center mb-5">
                    For Access to QstnAir
                </p>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control required type="text" placeholder="Full Name"
                            onChange={e => setName(e.target.value)}></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control required type="email" placeholder="name@address.com"
                            onChange={e => setEmail(e.target.value)}></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <div className="password-wrapper">
                            <Form.Control id="password" required type={pwdType} placeholder="Enter your password"
                                onChange={e => setPassword(e.target.value)} isInvalid={!!errors.password}></Form.Control>
                            <span className="eye-icon" onClick={togglePwdType}>
                                <Eye size={20} />
                            </span>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Alert show={showAlert} variant={alertVariant} onClose={() => setShowAlert(false)}>
                            {alertMessage}
                        </Alert>
                    </Form.Group>
                    <button className="btn btn-lg btn-block btn-primary mb-3" type="submit">
                        Sign up
                    </button>
                    <div className="text-center">
                        <small className="text-muted text-center">
                            Already have an account? <Link to="/login">Login</Link>
                        </small>
                    </div>
                    <div className="text-center">
                        <small className="text-muted text-center">
                            <Link to="/reset">Forgot password?</Link>
                        </small>
                    </div>
                </Form>
            </Col>
        </Row>
    )
}

export default Signup;