import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Login from 'views/Public/Login';
import Signup from 'views/Public/Signup';

import './index.scss';

function PublicLayout(props) {
    return (
        <div className="layout-public d-flex align-items-center bg-auth border-top border-top-2 border-primary">
            <Container>
                <Switch>
                    <Redirect exact from="/" to="/login" />
                    <Redirect exact from="/invites/:inviteId" to="/login?to=/user/invites/:inviteId" />
                    <Route path="/login" component={Login} />
                    <Route path="/signup" component={Signup} />
                    <Route path="/reset" component={Reset} />
                    <Route path="/reset-confirmation" component={ResetConfirmation} />
                    <Route component={NoMatch} />
                    {/* <Route render={() => <Redirect to="/" />} /> */}
                </Switch>
            </Container>
        </div>
    )
}

function NoMatch({ location }) {
    console.log('no match mounted');
    let unmounted = false;
    const [notified, setNotified] = useState(false);

    useEffect(() => {
        window.setTimeout(() => {
            if (!unmounted) {
                setNotified(true);
            }
        }, 2000);

        return () => {
            unmounted = true;
        }
    }, []);

    return (
        notified ?
            <Redirect to="/" />
            :
            <div>
                <h3>
                    No match for <code>{location.pathname}</code>
                </h3>
            </div>
    );
}

function Reset() {
    return (
        <div>
            <h2>Reset</h2>
        </div>
    );
}

function ResetConfirmation() {
    return (
        <div>
            <h2>ResetConfirmation</h2>
        </div>
    );
}

export default PublicLayout;