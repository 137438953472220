import React, { useEffect, useState } from 'react';
import { ProvideQstnr, useQstnr } from "hooks/use-qstnr.js";

function Preview(props) {
    const qstnrCxt = useQstnr();
    const qstnrId = props.match.params.qstnrId;

    useEffect(() => {
        qstnrCxt.loadQstnrDoc(qstnrId);
    }, []);

    return (
        <>
            <h3>QstnAir {qstnrId}</h3>
            {qstnrCxt.settings.fields.map(fld=><div>{fld.title}</div>)}
        </>
    )
}

function PreviewHOC(props) {
    return (
        <ProvideQstnr>
            <Preview {...props} />
        </ProvideQstnr>
    )
}

export default PreviewHOC;