import React from 'react';
import useSessionStorage from "hooks/session-storage.js";

export const GradientPicker = (props) => {

    const [gradients, setGradients] = useSessionStorage("ss_grdnts", "");

    const getGradient = (colors) => {
        return colors.join(", ");
    }

    const onSelectGradient = (indx) => {
        let gradient = gradients[indx];
        console.log(gradient);
        props.onChange(gradient);
    }
    
    return (
        <ul className="gradients-wrapper">
            {gradients.map((item, i) => <li key={i} onClick={()=>onSelectGradient(i)} className="gradient-item"
                style={{ background: 'linear-gradient(to right, ' + `${getGradient(item.colors)}` + ')' }}>
                {item.name}
            </li>)}
        </ul>
    )
}