import React from 'react';
import { ProvideFireUtils } from "hooks/use-fireutils";
import { ProvideStudio } from "hooks/use-studio.js";
import Studio from './Studio';

function StudioHOC(props) {
  return (
    <ProvideStudio>
      <ProvideFireUtils>
        <Studio {...props} />
      </ProvideFireUtils>
    </ProvideStudio>
  );
}

export default StudioHOC;