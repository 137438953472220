import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import { Edit, Settings, List, Share2, PlusCircle, Copy, Trash2 } from "react-feather";

import { useFirebase } from 'hooks/use-firebase';
import { ProvideQstnr } from "hooks/use-qstnr.js";
import Spinner from 'components/Spinner';
import { CustomDropdownToggle } from "components/CustomDropdownToggle";
import NewQstnrModal from './NewQstnrModal';

import "./QstnAirs.scss";
import { BGInput } from 'components/BGPicker';

const WorkspaceDetails = (props) => {
    const fireContext = useFirebase();
    const { fireDb } = fireContext;
    let { workspaceId } = useParams();
    const [workspace, setWorkspace] = useState(null);

    useEffect(() => {
        function onInit() {
            let docRef = fireDb.collection("workspaces").doc(workspaceId);
            docRef.get().then(function (doc) {
                if (doc.exists) {
                    setWorkspace(doc.data());
                }
            }).catch(function (error) {
                console.log("Error getting workspace document:", error);
            });
        };
        onInit();
    }, []);

    return (
        <h1 className="header-title">{workspace ? workspace.name : ''}</h1>
    )
}

function QstnAirs(props) {
    const fireContext = useFirebase();
    const { fireDb } = fireContext;
    let history = useHistory();
    let { workspaceId } = useParams();

    const [modalKey, setModalKey] = useState(0);
    const [data, setData] = useState([]);
    const [workspace, setWorkspace] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = React.useState(false);

    const handleEdit = (event, qstnrId) => {
        event.preventDefault();
        event.stopPropagation();
        console.log(qstnrId + " will be edited");
        history.push(`/qstnairs/${qstnrId}/studio`);
    }

    const handleSettings = (event, qstnrId) => {
        event.preventDefault();
        event.stopPropagation();
        console.log(qstnrId + " settings");
        history.push(`/qstnairs/${qstnrId}/settings`);
    }

    const handleRemove = (event, qstnrId) => {
        event.preventDefault();
        event.stopPropagation();
        console.log(qstnrId + " will be removed");
    }

    const getQstnrBG = (qstnr) => {
        if (qstnr.fields && qstnr.fields.length > 0) {
            let startField = qstnr.fields.find(item => item.type === 'start');
            return <BGInput bgType={startField.bgType} bgObject={startField.bgObject} isReadOnly={true} />
        }
        return <img src={`/assets/img/files/qstnair3.png`} alt="..." className="avatar-img rounded" />
    }

    useEffect(() => {

        function onInit() {
            setIsLoading(true);
            fireDb.collection("qstnairs").where("workspaceId", "==", workspaceId)
                .get()
                .then(function (querySnapshot) {
                    let arr = [];
                    querySnapshot.forEach(function (doc) {
                        var qstnr = doc.data();
                        qstnr.id = doc.id;
                        arr.push(qstnr);
                    });
                    let wsRef = fireDb.collection("workspaces").doc(workspaceId);
                    wsRef.get().then(function (doc) {
                        if (doc.exists) {
                            setWorkspace(doc.data());
                        }
                    }).catch(function (error) {
                        console.log("Error fetching workspace document:", error);
                    });
                    setData([...arr]);
                    setIsLoading(false);
                    console.log("qstnairs loaded");
                })
                .catch(function (err) {
                    setIsLoading(false);
                    console.log("Error fetching qstnairs: " + err);
                });
        }

        onInit();
    }, []);

    return (
        isLoading ? <Spinner /> :
            <React.Fragment>
                <ProvideQstnr>
                    <NewQstnrModal animation={false}
                        backdrop="static"
                        keyboard={false}
                        show={showModal}
                        onHide={() => { setModalKey(modalKey + 1); setShowModal(false) }}
                        key={modalKey}
                    />
                </ProvideQstnr>
                <div className="header mx-4">
                    <div className="container-fluid">
                        <div className="header-body">
                            <div className="row align-items-end">
                                <div className="col">
                                    {/* <h6 className="header-pretitle">Workspace</h6> */}
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">Home</li>
                                            <li className="breadcrumb-item"><Link to={"/workspaces"}>Workspaces</Link></li>
                                            <li className="breadcrumb-item">
                                                <a href="#" onClick={e => e.preventDefault()}>
                                                    {workspace ? workspace.name : ''}
                                                </a>
                                            </li>
                                        </ol>
                                    </nav>
                                    <h1 className="header-title">{workspace ? workspace.name : ''}</h1>
                                </div>
                                {/* <div className="col-auto">
                                    <Button variant="primary" onClick={() => setShowModal(true)}>Create New QstnAir</Button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content mx-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-8 offset-xl-2 col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h2 className="card-header-title">QstnAirs</h2>
                                        <a className="btn btn-primary" onClick={() => setShowModal(true)}><PlusCircle /> Create New QstnAir</a>
                                    </div>
                                    <div className="card-body">
                                        <ul className="list-group list-group-lg list-group-flush list my-n4">
                                            {
                                                data.map((qstnr, indx) =>
                                                    <li className="list-group-item" key={qstnr.id}>
                                                        <div className="row align-items-center">
                                                            <div className="col-auto">
                                                                <Link to={`/qstnairs/${qstnr.id}/studio`} className="avatar avatar-lg">
                                                                    {/* <img src={`/assets/img/files/qstnair${indx + 2}.png`} alt="..." className="avatar-img rounded" /> */}
                                                                    {getQstnrBG(qstnr)}
                                                                </Link>
                                                            </div>
                                                            <div className="col ml-n2">
                                                                <h4 className="mb-1 name">
                                                                    <Link to={`/qstnairs/${qstnr.id}/studio`}>{qstnr.title}</Link>
                                                                </h4>
                                                                <p className="card-text small text-muted mb-1">
                                                                    {qstnr.description}
                                                                </p>
                                                                {/* <p className="card-text small text-muted">
                                                                Last updated by USERNAME on <time datetime="2018-01-03">Jan 1, 2021</time>
                                                            </p> */}
                                                            </div>
                                                            <div className="col-auto d-flex">
                                                                <Link to={`/qstnairs/${qstnr.id}/studio`} className="btn btn-sm btn-outline-primary mr-2">
                                                                    <Edit size={16} /> Edit Questions
                                                    </Link>
                                                                <Link to={`/qstnairs/${qstnr.id}/settings`} className="btn btn-sm btn-outline-primary mr-2">
                                                                    <Settings size={16} /> Settings
                                                    </Link>
                                                                <Link to={`/qstnairs/${qstnr.id}/responses`} className="btn btn-sm btn-outline-primary mr-2">
                                                                    <List size={16} /> Responses
                                                    </Link>
                                                                <Link to={`/qstnairs/${qstnr.id}/share`} className="btn btn-sm btn-outline-primary mr-2">
                                                                    <Share2 size={16} /> Share
                                                    </Link>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle as={CustomDropdownToggle} />
                                                                    <Dropdown.Menu size="sm" title="" className="custom-dd-menu">
                                                                        <Dropdown.Item><Copy size={16} /> Copy To New</Dropdown.Item>
                                                                        <Dropdown.Item><Trash2 size={16} /> Delete</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {false && <div className="container-fluid">
                    <div className="row">
                        <div className="table-responsive mb-0" >

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Title</th>
                                        <th style={{ 'width': '5%' }}>Edit Questions</th>
                                        <th style={{ 'width': '5%' }}>Settings</th>
                                        <th style={{ 'width': '5%' }}>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.map((qstnr, indx) =>
                                            <tr key={qstnr.id}>
                                                <td>{indx + 1}</td>
                                                <td>{qstnr.title}</td>
                                                <td>
                                                    <a href="/" onClick={e => handleEdit(e, qstnr.id)}>
                                                        <i className="fe fe-edit"></i>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a href="/" onClick={e => handleSettings(e, qstnr.id)}>
                                                        <i className="fe fe-settings"></i>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a href="/" onClick={e => handleRemove(e, qstnr.id)}>
                                                        <i className="fe fe-trash-2"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>

                        </div>
                    </div>
                </div>}
            </React.Fragment>
    );
}

export default QstnAirs;