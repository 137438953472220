import $ from 'jquery';
import jqueryui from 'jquery-ui-dist/jquery-ui.min.js';
import { jsPlumb } from 'jsplumb';
import Panzoom from '@panzoom/panzoom';
import Utils from 'hooks/utils';

const BG_SRC_TGT = "#2C7BE5";
const HEX_SRC_ENDPOINT = BG_SRC_TGT;
const HEX_TGT_ENDPOINT = BG_SRC_TGT;
const HEX_ENDPOINT_HOVER = "#fd7e14";
//const HEX_CONNECTOR = "#A7DBD8";
//const HEX_CONNECTOR_HOVER = "#69D2E7";
const HEX_CONNECTOR = "#39afd1";
const HEX_CONNECTOR_HOVER = "#fd7e14";

const connectorPaintStyle = {
    strokeWidth: 2,
    stroke: HEX_CONNECTOR,
    joinstyle: "round",
    outlineStroke: "white",
    outlineWidth: 1
},
    connectorHoverStyle = {
        strokeWidth: 3,
        stroke: HEX_CONNECTOR_HOVER,
        outlineWidth: 2,
        outlineStroke: "white"
    },
    endpointHoverStyle = {
        fill: HEX_ENDPOINT_HOVER,
        stroke: HEX_ENDPOINT_HOVER
    },
    sourceEndpoint = {
        endpoint: "Dot",
        paintStyle: {
            stroke: HEX_SRC_ENDPOINT,
            fill: "transparent",
            radius: 4,
            strokeWidth: 3
        },
        isSource: true,
        connector: ["Flowchart", { stub: [40, 60], gap: 8, cornerRadius: 5, alwaysRespectStubs: true }],
        connectorStyle: connectorPaintStyle,
        hoverPaintStyle: endpointHoverStyle,
        connectorHoverStyle: connectorHoverStyle,
        dragOptions: {},
        overlays: [
            ["Label", {
                location: [0.5, 1.5],
                label: "Drag",
                cssClass: "endpointSourceLabel",
                visible: false
            }]
        ]
    },
    targetEndpoint = {
        endpoint: "Dot",
        paintStyle: {
            fill: HEX_TGT_ENDPOINT,
            radius: 5
        },
        hoverPaintStyle: endpointHoverStyle,
        maxConnections: -1,
        dropOptions: { hoverClass: "hover", activeClass: "active" },
        isTarget: true,
        overlays: [
            ["Label", { location: [0.5, -0.5], label: "Drop", cssClass: "endpointTargetLabel", visible: false }]
        ]
    };

const Plumber = (function () {

    var studioCxt;
    let fields = [];
    let hasFieldErrors = false;
    let errorFields = {};
    let generalErrors = [];
    const FlowCanvas = 'flow-canvas';
    let FlowCanvasElem;
    let panzoomInstance;

    function initJSPlumb(sCxt) {

        window.jsPlumbInstance = jsPlumb.getInstance({
            DragOptions: { cursor: 'pointer', zIndex: 12000 },
            ConnectionOverlays: [
                ["Arrow", { location: 1 }],
                ["Label", {
                    location: 0.1,
                    id: "label",
                    cssClass: "aLabel"
                }]
            ],
            Container: FlowCanvas
        });

        studioCxt = sCxt;

        FlowCanvasElem = $('#' + FlowCanvas);
        var fcWidth = FlowCanvasElem.width();
        var fcHeight = FlowCanvasElem.height();
        console.log(fcWidth + "  " + fcHeight);

        $("div.drag-clone").draggable({
            helper: "clone",
            zIndex: 100,
            scroll: false,
            start: function (event, ui) {
                var width = event.target.getBoundingClientRect().width;
                $(ui.helper).css({
                    'width': Math.ceil(width)
                });
            }
        });

        FlowCanvasElem.droppable({
            hoverClass: "drop-hover",
            tolerance: "pointer",
            drop: function (event, ui) {
                console.log('dropped...');
                var helper = $(ui.helper);
                var fieldId = Utils.getUniqueId();
                if (helper.hasClass('drag-clone')) {
                    var scale = panzoomInstance.getScale();
                    var fcPos = FlowCanvasElem.position();
                    console.log(fcPos);
                    console.log(-fcPos.left/scale + (event.pageX/scale ));
                    console.log(-fcPos.top/scale + (event.pageY/scale ));
                    helper.find('div.draggable').clone(false)
                        .animate({ 'min-height': '40px', width: '180px' })
                        .css({
                            position: 'absolute',
                            // left: fcWidth * .45 + (event.pageX / scale),
                            // top: fcHeight * .45 + (event.pageY / scale)
                            left: -fcPos.left/scale + (event.pageX / scale),
                            top: -fcPos.top/scale + (event.pageY / scale)
                        })
                        .attr('id', fieldId)
                        .appendTo($(this)).fadeIn('fast', function () {
                            var field = $("#" + fieldId);
                            var fieldType = field.data('type');

                            window.jsPlumbInstance.draggable(field, {
                                containment: "parent",
                                scroll: true,
                                grid: [50, 50],
                                stop: function (event, ui) {
                                    //console.log(ui);
                                }
                            });

                            $('div.field').removeClass('active');
                            field.removeClass('d-flex').addClass('active panzoom-exclude');
                            var cardBody = field.find('.card-body');
                            cardBody.removeClass('align-items-center d-flex');
                            var title = '<div class="title">Field Title</div>';
                            cardBody.find('.col').append(title)

                            //todo pra - remove the last two parameters in the method implementation and everywhere
                            addField(fieldId, fieldType, 0, 0);

                            var canBranch = studioCxt.fieldTypes.find(x => x.type === fieldType).canBranch;
                            var bottomEndpoints = (canBranch || fieldType === 'end') ? [] : ["BottomCenter"];
                            var topEndPoints = fieldType === 'start' ? [] : ["TopCenter"];
                            addEndpoints(fieldId, bottomEndpoints, topEndPoints);

                            // var scale = panzoomInstance.getScale();
                            // if (scale !== 1) {
                            //     $('#' + fieldId).css({
                            //         position: 'absolute',
                            //         left: 6280,
                            //         top: 4000
                            //     })
                            // }

                            window.jsPlumbInstance.revalidate(fieldId);
                        });
                }
            }
        });

        window.jsPlumbInstance.bind("connection", function (connInfo, originalEvent) {
            var srcId = connInfo.sourceId;
            var targetId = connInfo.targetId;
            console.log("new connection establisehd" + srcId + " " + targetId);
        });

        window.jsPlumbInstance.bind("connectionDetached", function (connInfo, originalEvent) {
            var srcId = connInfo.sourceId;
            var targetId = connInfo.targetId;
            console.log("connection detached" + srcId + " " + targetId);
        });

        window.jsPlumbInstance.bind("connectionMoved", function (connInfo, originalEvent) {
            var oriSrcId = connInfo.originalSourceId;
            var oriTargetId = connInfo.originalTargetId;
            var newSrcId = connInfo.newSourceId;
            var newTargetId = connInfo.newTargetId;
            console.log("connection moved" + oriSrcId + " " + oriTargetId + " to " + newSrcId + " " + newTargetId);
        });

        window.jsPlumbInstance.bind("click", function (conn, originalEvent) {
            if (window.confirm("Delete connection from " + conn.sourceId + " to " + conn.targetId + "?"))
                window.jsPlumbInstance.deleteConnection(conn);
        });

        window.jsPlumbInstance.bind("beforeDrop", function (connection) {
            var srcId = connection.sourceId;
            var targetId = connection.targetId;
            return srcId !== targetId;
        });

        $("div.draggable").on("dragstop", function (event, ui) {
            console.log("drag stopped" + ui);
        });

        FlowCanvasElem.on('click', '.field', function () {
            var elemField = $(this);
            $('div.field').removeClass('active');
            elemField.addClass('active');
            studioCxt.setFieldActive(elemField.attr('id'));
        });


        // Enable Panzoom
        const elem = document.getElementById(FlowCanvas);
        panzoomInstance = Panzoom(elem, {
            maxScale: 2,
            minScale: .2,
            step: .2,
            excludeClass: 'panzoom-exclude',
            canvas: true,
            handleStartEvent: e => {
                var fcPos = FlowCanvasElem.position();
                console.log(fcPos);
            }
        });

        //todo pra
        //use the panzoomend or panzoomchange or whereever event to set 
        //panzoom.setOptions({disablePan:true|false}) if its out/not-out of bounds

        console.log("jsPlumbLoaded, Panzoom enabled");
    }

    /* Panzoom Methods Start */
    function pzReset() {
        panzoomInstance.reset();
        updateScale();
    }

    function pzZoomIn() {
        panzoomInstance.zoomIn();
        updateScale();
    }

    function pzZoomOut() {
        panzoomInstance.zoomOut();
        updateScale();
    }

    function updateScale() {
        var scale = panzoomInstance.getScale() * 100;
        $('#pz-scale').text(parseInt(scale) + '%');
    }
    /* Panzoom Methods End */

    function reset() {
        jsPlumb.reset();
    }

    const addField = (id, type, x, y) => {
        studioCxt.addField(id, type, x, y);
        studioCxt.setFieldActive(id);
        if (type === 'boolean') {
            console.log('will render fields here');
        }
    }

    const removeFieldInCanvas = (fieldId) => {
        window.jsPlumbInstance.remove(fieldId);
    }

    const addEndpoints = (toId, sourceAnchors, targetAnchors) => {
        for (var i = 0; i < sourceAnchors.length; i++) {
            var sourceUUID = toId + sourceAnchors[i];
            window.jsPlumbInstance.addEndpoint(toId, sourceEndpoint, { anchor: sourceAnchors[i], uuid: sourceUUID });
            console.log('source:' + sourceUUID);
        }
        for (var j = 0; j < targetAnchors.length; j++) {
            var targetUUID = toId + targetAnchors[j];
            window.jsPlumbInstance.addEndpoint(toId, targetEndpoint, { anchor: targetAnchors[j], uuid: targetUUID });
            console.log('target:' + targetUUID);
        }
        $('.jtk-endpoint').addClass('panzoom-exclude');
    }

    const removeEndpoint = (uuid) => {
        console.log("removing..." + uuid);
        window.jsPlumbInstance.deleteEndpoint(uuid);
    }

    const updateBranchingInCanvas = (field, isChecked) => {
        var isMultiple = field.isMultiple || false;
        if (isChecked) {
            //remove the bottom endpoint and add endpoints to all choices
            removeEndpoint(field.id + 'BottomCenter');
            //add endpoint to all existing choices
            if (field.choices) {
                field.choices.forEach((choice) => {
                    console.log('adding ' + choice.id);
                    addEndpoints(choice.id, ["LeftMiddle"], []);
                });
            }
        } else {
            //add the bottom endpoint and remove endpoints from all choices
            //Designer.removeEndpoint(field.id + 'BottomCenter');
            addEndpoints(field.id, ["BottomCenter"], []);
            window.jsPlumbInstance.revalidate(field.id);
            if (field.choices) {
                field.choices.forEach((choice) => {
                    console.log('removing ' + choice.id);
                    removeEndpoint(choice.id + 'LeftMiddle');
                });
            }
            if (field.branches) {
                field.branches.forEach((branch) => {
                    removeChoiceInCanvas(field.id, branch);
                });
            }
        }
    }

    const addBranchInCanvas = (fieldId, fieldBranch, branchTypes) => {
        var field = FlowCanvasElem.find('#' + fieldId).find('.card-body');
        var ul = field.find('ul');
        if (ul.length === 0) {
            ul = $("<ul/>").appendTo(field);
        }
        var branchTypeSymbol = branchTypes.find(x => x.type === fieldBranch.type).symbol;
        ul.append('<li id="' + fieldBranch.id + '" class="innerfield">' + branchTypeSymbol + ' ' + fieldBranch.value + '</li>');
        addEndpoints(fieldBranch.id, ["LeftMiddle"], []);
    }

    const updateBranchInCanvas = (fieldId, fieldBranch, branchTypes) => {
        var text = fieldBranch.value;
        var str = text.length > 23 ? text.substring(0, 20) + '...' : text;
        var branch = FlowCanvasElem.find('#' + fieldId).find('li#' + fieldBranch.id);
        var branchTypeSymbol = branchTypes.find(x => x.type === fieldBranch.type).symbol;
        branch.text(branchTypeSymbol + ' ' + str);
    }

    const updateTitleInCanvas = (fieldId, title) => {
        var str = title.length > 33 ? title.substring(0, 30) + '...' : title;
        FlowCanvasElem.find('#' + fieldId + ' .title').text(str);
    }

    const addChoiceInCanvas = (fieldId, fieldChoice, isBranchingEnabled) => {
        var field = FlowCanvasElem.find('#' + fieldId).find('.card-body');
        var ul = field.find('ul');
        if (ul.length === 0) {
            ul = $("<ul/>").appendTo(field);
        }
        ul.append('<li id="' + fieldChoice.id + '" class="innerfield">' + fieldChoice.text + '</li>');
        if (isBranchingEnabled) {
            addEndpoints(fieldChoice.id, ["LeftMiddle"], []);
        }
        window.jsPlumbInstance.revalidate(fieldId);
    }

    const updateChoiceInCanvas = (fieldId, fieldChoice) => {
        var text = fieldChoice.text;
        console.log(text);
        var str = text.length > 20 ? text.substring(0, 17) + '...' : text;
        var li = FlowCanvasElem.find('#' + fieldId).find('li#' + fieldChoice.id);
        li.text(str);
    }

    const removeChoiceInCanvas = (fieldId, fieldChoice) => {
        var li = FlowCanvasElem.find('#' + fieldId).find('li#' + fieldChoice.id);
        window.jsPlumbInstance.remove(fieldChoice.id);
    }

    const addAllEndpoints = (field) => {
        var srcs = [];
        if (field.canBranch && field.isBranchingEnabled) {
            var items = field.type === 'imagechoice' ? field.images : (field.hasChoices ? field.choices : field.branches);
            items.forEach(item => {
                addEndpoints(item.id, ["LeftMiddle"], []);
            });
        } else {
            addEndpoints(field.id, ["BottomCenter"], []);
        }
    }

    const setCanvasState = (nodes, connections) => {
        nodes.forEach((node, indx) => {
            var nodeHtml = node.htm;
            $(nodeHtml).addClass('exField').appendTo(FlowCanvasElem);
            var field = Plumber.fields[indx];
            addEndpoints(field.id, [], ["TopCenter"]);
            addAllEndpoints(field);
            //window.jsPlumbInstance.revalidate(field.id);
            //updateBranchingInCanvas(field, field.isBranchingEnabled);
            //.css({left: node.x, top: node.y});
        });

        window.jsPlumbInstance.draggable($('div.exField'), {
            containment: "parent",
            scroll: true,
            grid: [20, 20],
            stop: function (event, ui) {
                //console.log(ui);
            }
        });

        connections.forEach(conn => {
            var anchors = [];
            conn.anchors.forEach(anchor => {
                anchors.push(anchor.ep)
            });
            console.log(conn.src);
            window.jsPlumbInstance.connect({
                uuids: conn.endpoints
            });
        });
    }

    const getCanvasState = () => {
        var nodes = [];
        var connections = [];
        FlowCanvasElem.find('div.field').each(function (idx, elem) {
            var elem = $(elem);
            nodes.push({
                id: elem.attr('id'),
                x: parseInt(elem.css('left'), 10),
                y: parseInt(elem.css('top'), 10),
                htm: elem[0].outerHTML
            });
        })
        $.each(window.jsPlumbInstance.getConnections(), function (idx, conxn) {
            console.log("src-tgt:" + conxn.sourceId + conxn.targetId);
            connections.push({
                id: conxn.id,
                src: conxn.sourceId,
                tgt: conxn.targetId,
                endpoints: [conxn.endpoints[0].getUuid(), conxn.endpoints[1].getUuid()],
                anchors: $.map(conxn.endpoints, function (endpoint) {
                    var id = endpoint.id;
                    var uuid = endpoint.getUuid();
                    return {
                        "ep": [endpoint.anchor.x,
                        endpoint.anchor.y,
                        endpoint.anchor.orientation[0],
                        endpoint.anchor.orientation[1],
                        endpoint.anchor.offsets[0],
                        endpoint.anchor.offsets[1]]
                    };
                })
            })
        });
        return { nodes, connections };
    }

    const addFieldError = (field, errMsg) => {
        if (!hasFieldErrors) {
            hasFieldErrors = true;
        }
        field.errors.push(errMsg);
        if (!errorFields[field.id]) {
            errorFields[field.id] = true;
            $('div.field#' + field.id).addClass('error');
        }
        // if (!fieldErrors[fld]) {
        //     fieldErrors[fld] = [errMsg];
        // } else {
        //     (fieldErrors[fld]).push(errMsg);
        // }
    }

    const errorTemplate = (arrErrors) => {
        return `
        <div class="errors">
            ${arrErrors.join("<br>")}
        </div>`;
    }

    const validateCanvasState = () => {

        //Move the validation code to plumber
        //loop through all fields
        //each field should have a title
        //if field has branches, each branch should have a connection to a field id
        //if field does not have branches, it should be connected to a field id
        //if field has choices and branch on chocies, each choice should have a connection to a field id
        //if field has choices and branch on chocies, each choice should have a connection to a field id
        //any choice should not be empty

        //todo - only one entry point to be there. not many fields
        //todo - the image choice field is not restored (connections lost) when reloaded

        //clear all errors
        hasFieldErrors = false;
        errorFields = {};
        generalErrors = [];
        $('div.field .errors').remove();
        $('div.field').removeClass('error active');

        var connections = window.jsPlumbInstance.getConnections();

        var starts = Plumber.fields.filter(fld => fld.type === 'start');
        if (!starts.length) {
            generalErrors.push('Start Field not specified.');
            return { result: false, generalErrors };
        } else if (starts.length > 1) {
            generalErrors.push('Only one Start Field must be specified.');
            return { result: false, generalErrors };
        }

        var ends = Plumber.fields.filter(fld => fld.type === 'end');
        if (!ends.length) {
            generalErrors.push('At least one End Field must be specified.');
            return { result: false, generalErrors };
        }

        //Check for titles
        Plumber.fields.forEach(fld => {
            fld.errors = [];
            //General field-level errors
            if (!fld.title || fld.title === 'Field Title') {
                //$('div#'+fld.id).addClass('error').append(strHtml);
                //errors.push({[fld.id]:'Title may not be empty'});
                addFieldError(fld, 'Title may not be empty');
            }

            //no branches or choices, must be connected to any other field
            // i.e. it must be a source or target            
            if (!fld.hasChoices && !fld.isBranchingEnabled) {
                let conxnSrc = connections.find(cnn => cnn.sourceId == fld.id);
                let conxnTgt = connections.find(cnn => cnn.targetId == fld.id);
                if (!conxnSrc && !conxnTgt) {
                    addFieldError(fld, 'Isolated Field. No connections.');
                }
            }
            else {
                if (fld.hasChoices) {
                    if (fld.type === 'imagechoice') {
                        if (fld.images.length === 0) {
                            addFieldError(fld, 'No images specified.');
                        }
                    }
                    else if (fld.choices.length === 0) {
                        addFieldError(fld, 'No choices specified.');
                    }
                    else if (fld.isBranchingEnabled) {
                        //branching on choices
                        let conxnTargets = [];
                        fld.choices.forEach(choice => {
                            if (choice.text === '') {
                                addFieldError(fld, 'Choice text empty:' + choice.id);
                            }
                            //confirm if the choice is a source
                            let conxn = connections.find(cnn => cnn.sourceId == choice.id);
                            if (!conxn) {
                                addFieldError(fld, 'Choice not connected:' + choice.id + '/' + choice.text);
                            } else {
                                conxnTargets.push(conxn.targetId);
                            }
                        });
                        var thisFldTargets = conxnTargets.filter(cnn => cnn.includes(fld.id));
                        if (conxnTargets.length === thisFldTargets.length) {
                            addFieldError(fld, 'Isolated Field. No choice connected outside.');
                        }
                    } else {
                        let conxnSrc = connections.find(cnn => cnn.sourceId == fld.id);
                        let conxnTgt = connections.find(cnn => cnn.targetId == fld.id);
                        if (!conxnSrc && !conxnTgt) {
                            addFieldError(fld, 'Isolated Field. No connections1.');
                        }
                    }
                } else if (fld.isBranchingEnabled) {
                    if (fld.branches.length === 0) {
                        addFieldError(fld, 'No branches specified');
                    } else {
                        //get all the conxn targets to make sure there aren't any isolated fields
                        let conxnTargets = [];
                        fld.branches.forEach(brch => {
                            if (brch.value === '') {
                                addFieldError(fld, 'Branch value empty:' + brch.id + '/' + brch.type);
                            }
                            //confirm if the branch is a source
                            let conxn = connections.find(cnn => cnn.sourceId == brch.id);
                            if (!conxn) {
                                addFieldError(fld, 'Branch not connected:' + brch.id + '/' + brch.type);
                            } else {
                                conxnTargets.push(conxn.targetId);
                            }
                        });
                        var thisFldTargets = conxnTargets.filter(cnn => cnn.includes(fld.id));
                        if (conxnTargets.length === thisFldTargets.length) {
                            addFieldError(fld, 'Isolated Field. No branch connected outside.');
                        }
                    }
                }
            }
        });

        if (!hasFieldErrors) {
            var allFields = Plumber.fields.map(fld => fld.id);
            var allTargets = Array.from(new Set(connections.map(cnn => cnn.targetId)));
            var notTargets = allFields.filter(fld => !allTargets.includes(fld));
            var startField = Plumber.fields.find(fld => fld.type === 'start');
            var notTargetsStart = notTargets.filter(fld => fld !== startField.id);
            if (notTargetsStart.length > 0) {
                generalErrors.push('All fields must be set as a Target (i.e. connected from another field). Only the start field is an exception.')
                return { result: false, generalErrors };
            }

            //generate the data flow. find the next field for each field.
            Plumber.fields.forEach(fld => {
                let nxt = {};
                if (fld.hasChoices) {
                    if (fld.isBranchingEnabled) {
                        fld.choices.forEach(choice => {
                            let conxn = connections.find(cnn => cnn.sourceId == choice.id);
                            nxt[choice.id] = conxn.targetId;
                        });
                    } else {
                        //no branching. get the next field
                        let conxn = connections.find(cnn => cnn.sourceId == fld.id);
                        nxt[fld.id] = conxn ? conxn.targetId : '';
                    }
                }
                else {
                    if (fld.isBranchingEnabled) {
                        fld.branches.forEach(brch => {
                            let conxn = connections.find(cnn => cnn.sourceId == brch.id);
                            nxt[brch.id] = conxn.targetId;
                        });
                    } else {
                        //no branching. get the next field
                        let conxn = connections.find(cnn => cnn.sourceId == fld.id);
                        nxt[fld.id] = conxn ? conxn.targetId : '';
                    }
                }
                fld.nxt = nxt;
            });
            return { result: true };
        } else {
            //displayErrors();
            return { result: false };
        }
        return { result: false };
    }

    return {
        reset,
        initJSPlumb,
        removeFieldInCanvas,
        updateBranchingInCanvas,
        addBranchInCanvas,
        updateBranchInCanvas,
        updateTitleInCanvas,
        addChoiceInCanvas,
        updateChoiceInCanvas,
        removeChoiceInCanvas,
        fields,
        getCanvasState,
        setCanvasState,
        validateCanvasState,
        pzReset,
        pzZoomIn,
        pzZoomOut
    };

})();

export default Plumber;