import React, { useState, useEffect } from 'react';
import { useFirebase } from 'hooks/use-firebase';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useAuth } from 'hooks/use-auth';

function Invite(props) {

    const fireContext = useFirebase();
    const { fireDb } = fireContext;   
    const auth = useAuth();
    const user = auth.user;
    const userProfile = JSON.parse(auth.userProfile);
    console.log(userProfile);

    const { acctId, inviteId } = props.match.params;
    const [inviteData, setInviteData] = useState(null);

    const handleAccept = event => {
        alert(inviteId);
        event.preventDefault();
        if (inviteData && inviteData.email === userProfile.email) {
            fireDb.collection("accounts/" + acctId + "/users").doc(user.uid).set({
                uid: user.uid,
                email: userProfile.email,
                roles: ["editor"]
            })
                .then(() => {
                    var usersUpdate = {};
                    usersUpdate[`accounts.${acctId}`] = true;
                    usersUpdate[`acctRoles.${acctId}`] = ["soldi"];
                    console.log(usersUpdate)
                    fireDb.collection("users").doc(user.uid).set({
                        accounts: { [`${acctId}`]: true },
                        acctRoles: { [`${acctId}`]: ["editor"] }
                    }, { merge: true })
                        .then(function (res) {
                            alert('updated')
                        })
                    // db.collection("users").doc(user.uid).set({
                    //     [`accounts.${acctId}`]: true,
                    //     acctRoles: {
                    //         [`accounts.${acctId}`]: ["editor"]
                    //     }
                    // })
                })
                .catch(function (err) { alert(err) });

            //accept changes --
            //add this user to the list of acct users
            //add the acctId to the list of accounts in user profile
            // db.collection("accounts/" + acctId + "/users").add()
            // db.collection("accounts/" + acctId + "/users").add({
            //     email: email,
            //     roles: ["editor"]
            //   })
        }
    }

    useEffect(() => {
        const onInit = () => {
            var docRef = fireDb.collection("accounts/" + acctId + "/invites").doc(inviteId);
            docRef.get().then(function (doc) {
                if (doc.exists) {
                    console.log("Invite data:", doc.data());
                    setInviteData(doc.data());
                }
            }, function (err) {
                console.log('doc get error:' + err);
            });
        }
        onInit();
    }, []);
    return (
        <Row>
            <Col size={12}>
                <form>
                    <h2>Invitation</h2>
                    <button type="submit" onClick={handleAccept}>Accept</button>
                </form>
            </Col>
        </Row>
    )
}

export default Invite;