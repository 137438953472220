// Hook (use-studio.js)
import React, { useState, useContext, createContext, useEffect } from "react";
import { useFirebase } from 'hooks/use-firebase';
import Utils from 'hooks/utils';
import Plumber from 'hooks/plumber';

const studioContext = createContext();

export function ProvideStudio({ children }) {
    const studio = useProvideStudio();
    return <studioContext.Provider value={studio}>{children}</studioContext.Provider>;
}

export const useStudio = () => {
    return useContext(studioContext);
};

class Field {

    constructor(id, type, x, y) {
        const fieldTypeDef = FieldTypes.find(x => x.type === type);
        Object.assign(this, fieldTypeDef);
        this.id = id;
        this.x = x;
        this.y = y;
        this.typeLabel = fieldTypeDef.title;
        this.title = 'Field Title';
        this.isRequired = false;

        if (this.canBranch) {
            this.isBranchingEnabled = this.canBranch;
            this.isRequired = this.canBranch;
            this.branches = [];

            // if (this.type === 'switch') {
            //     const branchYes = new Branch(Utils.getUniqueId());
            //     branchYes.type = BranchTypes[this.dataType][0].type;
            //     this.branches.push(branchYes);

            //     const branchNo = new Branch(Utils.getUniqueId());
            //     branchNo.type = BranchTypes[this.dataType][1].type;
            //     this.branches.push(branchNo);
            // }
        }

        if (this.hasChoices) {
            this.choices = [];
            if (this.type === 'imagechoice') {
                this.images = [];
            }
        }

        if (this.canMultiple) {
            this.allowMultiple = false;
        }

        if (this.type === 'number') {
            this.numberType = 'integer';
        }

        if (this.type === 'starrating') {
            this.ratingIconType = 'star';
            this.ratingScale = 5;
            this.isHalfAllowed = false;
        }

        if (this.type === 'nps') {
            this.leftLabel = 'Not likely';
            this.rightLabel = 'Extremely likely';
        }

        if (['start', 'end'].includes(this.type)) {
            // start = sublime vivid
            // end = pizelex (or ali)
            this.bgType = 'gradient';
            if (this.type === 'start') {
                this.bgObject = {
                    "name": "Sublime Vivid",
                    "colors": ["#FC466B", "#3F5EFB"]
                }
            }
            else {
                this.bgObject = {
                    "name": "Pizelex",
                    "colors": ["#114357", "#F29492"]
                }
            }
            // this.bgObject = {
            //     hex: "#64b5f6",
            //     rgb: { r: 100, g: 181, b: 246, a: 1 }
            // }
        }
    }
}

class Choice {
    constructor(id) {
        this.id = id;
        //this.value = '';
        this.text = 'new choice...';
    }
}


class Branch {
    constructor(id) {
        this.id = id;
        this.type = '';
        this.value = '';
    }
}

const BranchTypes = {
    text: [
        { type: 'equals', title: 'Equals', symbol: '=' },
        { type: 'notequals', title: 'Not Equals', symbol: '!=' },
        { type: 'contains', title: 'Contains', symbol: '%ABC%' }
    ],
    numeric: [
        { type: 'equals', title: 'Equals', symbol: '=' },
        { type: 'notequals', title: 'Not Equals', symbol: '!=' },
        { type: 'gt', title: 'Greater Than', symbol: '>' },
        { type: 'lt', title: 'Less Than', symbol: '<' },
        { type: 'gteq', title: 'Greater or Equals', symbol: '>=' },
        { type: 'lteq', title: 'Less or Equals', symbol: '<=' }
    ],
    datetime: [
        { type: 'equals', title: 'Equals', symbol: '=' },
        { type: 'gt', title: 'Greater Than', symbol: '>' },
        { type: 'lt', title: 'Less Than', symbol: '<' },
        { type: 'gteq', title: 'Greater or Equals', symbol: '>=' },
        { type: 'lteq', title: 'Less or Equals', symbol: '<=' }
    ],
    boolean: [
        { type: 'yes', title: 'Yes', symbol: 'Yes' },
        { type: 'no', title: 'No', symbol: 'No' }
    ],
    binary: []
}

const FieldTypes = [
    {
        type: 'start', title: 'Welcome Slide', canBranch: false, dataType: 'label'
    },
    {
        type: 'text', title: 'Text/Email', canBranch: false, dataType: 'text',
        isLimited: false, minLength: 0, maxLength: 10000
    },
    {
        type: 'textarea', title: 'Comment Box', canBranch: false, dataType: 'text',
        isLimited: false, minLength: 0, maxLength: 10000
    },
    {
        type: 'number', title: 'Number', canBranch: true, dataType: 'numeric',
        canRange: true, isRanged: false, minValue: null, maxValue: null
    },
    {
        type: 'date', title: 'Date', canBranch: true, dataType: 'datetime',
        canRange: true, isRanged: false, minValue: null, maxValue: null
    },
    {
        type: 'daterange', title: 'Date Range', canBranch: false, dataType: 'datetime',
        canRange: true, isRanged: false, minValue: null, maxValue: null
    },
    //{ type: 'time', title: 'Time', canBranch: true, dataType: 'datetime' },
    //{ type: 'timerange', title: 'TimeRange', canBranch: true, dataType: 'datetime' },
    {
        type: 'select', title: 'Select', canBranch: true, dataType: 'text',
        hasChoices: true, canMultiple: true
    },
    {
        type: 'checkbox', title: 'Checkbox', canBranch: true, dataType: 'text',
        hasChoices: true, canMultiple: true
    },
    {
        type: 'radio', title: 'Radio', canBranch: true, dataType: 'text',
        hasChoices: true
    },
    {
        type: 'ranking', title: 'Ranking (Sort)', canBranch: true, dataType: 'numeric',
        hasChoices: true
    },
    { type: 'nps', title: 'Rating Scale', canBranch: true, dataType: 'numeric' },
    { type: 'starrating', title: 'Star Rating', canBranch: true, dataType: 'numeric', maxValue: 5 },
    { type: 'matrixrating', title: 'Matrix Rating', canBranch: true, dataType: 'text' },
    {
        type: 'slider', title: 'Slider', canBranch: true, dataType: 'numeric',
        canRange: true, isRanged: true, minValue: 0, maxValue: 100, increment: 1
    },
    {
        type: 'imagechoice', title: 'Image Choice', canBranch: true, dataType: 'text',
        hasChoices: true, canMultiple: true, images: []
    },
    // {
    //     type: 'video', title: 'Video (YouTube)', canBranch: true, dataType: 'text',
    //     hasChoices: true, canMultiple: true
    // },
    {
        type: 'attachment', title: 'Attachment', canBranch: false, dataType: 'binary',
        canMultiple: true, maxCount: 5, allowComment: false, allowedFileTypes: []
    },
    {
        type: 'end', title: 'Thank You Slide', canBranch: false, dataType: 'label'
    },
]

const RatingIcons = [
    { type: 'star', title: 'Star', isHalfAllowed: false },
    { type: 'heart', title: 'Heart', isHalfAllowed: false },
    // { type: 'thumbs', title: 'Thumbs up', isHalfAllowed: false },
    // { type: 'smiley', title: 'Smiley', isHalfAllowed: false }
]

const NumberTypes = [
    { type: 'integer', title: 'Integer' },
    { type: 'decimal', title: 'Decimal' },
]

const AttachmentTypes = [
    { type: 'doc', fileTypes: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'] },
    { type: 'img', fileTypes: ['jpg', 'png', 'gif', 'svg'] }
]

function useProvideStudio() {

    const fireContext = useFirebase();
    const { firebaseRef, fireDb } = fireContext;
    let canvasState = [];
    //const [canvasState, setCanvasState] = useState([]);
    const [activeField, setActiveField] = useState(null);
    const [qstnrDocRef, setQstnrDocRef] = useState(null);

    useEffect(() => {
        if (activeField) {
            var foundIndex = Plumber.fields.findIndex(x => x.id == activeField.id);
            Plumber.fields[foundIndex] = Object.assign({}, activeField);
        }
    }, [activeField])

    function addField(id, type, x, y) {
        const field = new Field(id, type, x, y);
        Plumber.fields.push(field);
        //setFields(fields => [...fields, field]);
    }

    function setFieldActive(fieldId) {
        var field = fieldId ? Plumber.fields.find(x => x.id === fieldId) : null;
        setActiveField(field);
    }

    function removeField(fieldId) {
        Plumber.fields = Plumber.fields.filter(x => x.id !== fieldId);
        // let newFields = fields.filter(x => x.id !== fieldId);
        //setFields([...newFields]);
        setActiveField(null);
        Plumber.removeFieldInCanvas(fieldId);
    }

    function setBranching(checked) {
        var field = Object.assign({}, activeField);
        field.isBranchingEnabled = checked;
        if (!checked) {
            field.branches = [];
        } else {
            field.isRequired = true;
        }
        setActiveField(field);
        Plumber.updateBranchingInCanvas(activeField, checked);
    }

    function addBranch() {
        var field = Object.assign({}, activeField);
        var branchTypes = this.branchTypes[field.dataType];
        var newBranch = new Branch(Utils.getUniqueId());
        newBranch.type = branchTypes[0].type;
        if (!field.branches) {
            field.branches = [];
        }
        field.branches.push(newBranch);
        setActiveField(field);
        Plumber.addBranchInCanvas(field.id, newBranch, branchTypes);
    }

    function setBranchType(index, type) {
        var field = Object.assign({}, activeField);
        field.branches[index].type = type;
        setActiveField(field);
        Plumber.updateBranchInCanvas(field.id, field.branches[index], this.branchTypes[field.dataType]);
    }

    function setBranchValue(index, value) {
        var field = Object.assign({}, activeField);
        field.branches[index].value = value;
        setActiveField(field);
        Plumber.updateBranchInCanvas(field.id, field.branches[index], this.branchTypes[field.dataType]);
    }

    function removeBranch(index) {
        var field = Object.assign({}, activeField);
        Plumber.removeChoiceInCanvas(field.id, field.branches[index]);
        field.branches.splice(index, 1);
        setActiveField(field);
    }

    function setTitle(title) {
        var field = Object.assign({}, activeField);
        field.title = title;
        setActiveField(field);
        Plumber.updateTitleInCanvas(field.id, title);
    }

    function setDesc(desc) {
        var field = Object.assign({}, activeField);
        field.desc = desc;
        setActiveField(field);
    }

    function setBGProps(bgType, bgObject) {
        var field = Object.assign({}, activeField);
        field.bgType = bgType;
        field.bgObject = bgObject;
        setActiveField(field);
    }

    function setIsRequired(checked) {
        var field = Object.assign({}, activeField);
        field.isRequired = checked;
        setActiveField(field);
    }

    function setIsEmail(checked) {
        var field = Object.assign({}, activeField);
        field.isEmail = checked;
        setActiveField(field);
    }

    function setIsLimited(checked) {
        var field = Object.assign({}, activeField);
        field.isLimited = checked;
        if (!checked) {
            field.minLength = 0;
            field.maxLength = 10000;
        } else {
            field.minLength = 1;
            field.maxLength = 1000;
        }
        setActiveField(field);
    }

    function setMinLength(value) {
        var field = Object.assign({}, activeField);
        field.minLength = value;
        setActiveField(field);
    }

    function setMaxLength(value) {
        var field = Object.assign({}, activeField);
        field.maxLength = value;
        setActiveField(field);
    }

    function setIsRanged(checked) {
        var field = Object.assign({}, activeField);
        field.isRanged = checked;
        if (!checked) {
            if (field.dataType == 'datetime') {
                field.minDate = null;
                field.maxDate = null;
            } else {
                field.minValue = null;
                field.maxValue = null;
            }
        } else {
            if (field.dataType == 'datetime') {
                field.minDate = new Date(1900, 0, 1);
                field.maxDate = new Date(2100, 11, 31);
            } else {
                field.minValue = 0;
                field.maxValue = 100;
            }
        }
        setActiveField(field);
    }

    function setNumberType(value) {
        var field = Object.assign({}, activeField);
        field.numberType = value;
        setActiveField(field);
    }

    function setMinValue(value) {
        var field = Object.assign({}, activeField);
        field.minValue = value;
        setActiveField(field);
    }

    function setMaxValue(value) {
        var field = Object.assign({}, activeField);
        field.maxValue = value;
        setActiveField(field);
    }

    function setMinDate(value) {
        var field = Object.assign({}, activeField);
        field.minDate = value;
        setActiveField(field);
    }

    function setMaxDate(value) {
        var field = Object.assign({}, activeField);
        field.maxDate = value;
        setActiveField(field);
    }

    function setLeftLabel(value) {
        var field = Object.assign({}, activeField);
        field.leftLabel = value;
        setActiveField(field);
    }

    function setRightLabel(value) {
        var field = Object.assign({}, activeField);
        field.rightLabel = value;
        setActiveField(field);
    }

    function setAllowMultiple(value) {
        var field = Object.assign({}, activeField);
        if (!value && field.branches.length > 0) {
            field.branches.forEach((bch, i) => {
                Plumber.removeChoiceInCanvas(field.id, field.branches[i]);
            });
            field.branches = [];
        }
        field.allowMultiple = value;
        setActiveField(field);
    }

    function addChoice() {
        var field = Object.assign({}, activeField);
        var newChoice = new Choice(Utils.getUniqueId());
        if (!field.choices) {
            field.choices = [];
        }
        field.choices.push(newChoice);
        setActiveField(field);
        Plumber.addChoiceInCanvas(field.id, newChoice, field.isBranchingEnabled);
    }

    function setChoiceValue(index, value) {
        var field = Object.assign({}, activeField);
        field.choices[index].value = value;
        setActiveField(field);
    }

    function setChoiceText(index, text) {
        var field = Object.assign({}, activeField);
        field.choices[index].text = text;
        setActiveField(field);
        Plumber.updateChoiceInCanvas(this.activeField.id, field.choices[index]);
    }

    function removeChoice(index) {
        var field = Object.assign({}, activeField);
        Plumber.removeChoiceInCanvas(this.activeField.id, field.choices[index]);
        field.choices.splice(index, 1);
        setActiveField(field);
    }

    function setImageCaption(index, text) {
        var field = Object.assign({}, activeField);
        field.images[index].caption = text;
        setActiveField(field);
    }

    function removeImageChoice(index) {
        const charArr = 'ABCDEFGHIJ'.split('');
        var field = Object.assign({}, activeField);
        Plumber.removeChoiceInCanvas(this.activeField.id, field.images[index]);
        field.images.splice(index, 1);
        field.images.forEach((img, indx) => {
            img.text = 'Image Choice (' + charArr[indx] + ')';
            Plumber.updateChoiceInCanvas(field.id, img);
        })
        setActiveField(field);
    }

    function setRatingIconType(iconType) {
        var field = Object.assign({}, activeField);
        field.ratingIconType = iconType;
        const isHalfAllowed = RatingIcons.find(x => x.type === iconType).isHalfAllowed;
        if (!isHalfAllowed) {
            field.ratingHalfAllowed = false;
        }
        setActiveField(field);
    }

    function setRatingScale(scale) {
        var field = Object.assign({}, activeField);
        field.ratingScale = scale;
        setActiveField(field);
    }

    function setRatingHalfAllowed(checked) {
        var field = Object.assign({}, activeField);
        field.ratingHalfAllowed = checked;
        setActiveField(field);
    }

    function setAllowAttachmentComment(checked) {
        var field = Object.assign({}, activeField);
        field.allowComment = checked;
        setActiveField(field);
    }

    function setAllowedAttachmentTypes(attType, checked) {
        var field = Object.assign({}, activeField);
        if (checked) {
            field.allowedFileTypes.push(attType);
        }
        else {
            field.allowedFileTypes = field.allowedFileTypes.filter(f => f !== attType)
        }
        field.allowedFileTypes[attType] = checked;
        setActiveField(field);
    }

    function addImages(newImages) {
        const charArr = 'ABCDEFGHIJ'.split('');
        var field = Object.assign({}, activeField);
        if (!field.images) {
            field.images = [];
        }
        newImages.forEach((img, indx) => {
            img.id = Utils.getUniqueId();
            img.text = 'Image Choice (' + charArr[field.images.length + indx] + ')';
            Plumber.addChoiceInCanvas(field.id, img, true);
            Plumber.updateChoiceInCanvas(field.id, img);
        })
        field.images.push(...newImages);
        // function addChoice() {
        //     var field = Object.assign({}, activeField);
        //     var newChoice = new Choice(Utils.getUniqueId());
        //     if (!field.choices) {
        //         field.choices = [];
        //     }
        //     field.choices.push(newChoice);
        //     setActiveField(field);
        //     Plumber.addChoiceInCanvas(field.id, newChoice, field.isBranchingEnabled);
        // }

        setActiveField(field);
    }

    function loadQstnrDoc(docId) {
        let docRef = fireDb.collection("qstnairs").doc(docId);
        setQstnrDocRef(docRef);
        return docRef.get();

        /*
        docRef.get().then(function (doc) {
            if (doc.exists) {
                console.log("Document data:", doc.data());
                let data = doc.data();
                //fields = data.fields || [];
                if (data.fields.length > 0) {
                    //setFields(data.fields || []);
                    Plumber.fields = data.fields;
                    Plumber.setCanvasState(data.nodes, data.connections);
                    console.log('will load current state of canvas');
                }

                // canvasState = data.canvasState || [];
                // if (Plumber.fields.length > 0) {
                //     //load current canvas state
                // }
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
        */
    }

    function saveQstnrDoc() {
        const fields = JSON.parse(JSON.stringify(Plumber.fields));
        return qstnrDocRef.update({
            fields,
            ...canvasState,
            udpated: firebaseRef.firestore.FieldValue.serverTimestamp()
        })
        // .then(function () {
        //     console.log("Document successfully updated!");
        // })
        // .catch(function (error) {
        //     // The document probably doesn't exist.
        //     console.error("Error updating document: ", error);
        // });
    }

    function getCanvasStateAndFields() {
        const fields = JSON.parse(JSON.stringify(Plumber.fields));
        return { fields, ...Plumber.getCanvasState() };
    }

    function saveCanvasState() {
        canvasState = Plumber.getCanvasState();
        console.log(canvasState);
    }

    function saveDataFlow() {

    }

    return {
        fieldTypes: FieldTypes,
        branchTypes: BranchTypes,
        ratingIcons: RatingIcons,
        numberTypes: NumberTypes,
        attachmentTypes: AttachmentTypes,
        activeField,
        setFieldActive,
        addField,
        removeField,
        setTitle,
        setDesc,
        setBGProps,
        setIsRequired,
        setIsEmail,
        setIsLimited,
        setMinLength,
        setMaxLength,
        setMinDate,
        setMaxDate,
        setIsRanged,
        setNumberType,
        setMinValue,
        setMaxValue,
        setMinDate,
        setMaxDate,
        setLeftLabel,
        setRightLabel,
        addBranch,
        setBranching,
        setBranchType,
        setBranchValue,
        removeBranch,
        setAllowMultiple,
        addChoice,
        setChoiceValue,
        setChoiceText,
        removeChoice,
        setImageCaption,
        removeImageChoice,
        setRatingIconType,
        setRatingScale,
        setRatingHalfAllowed,
        setAllowAttachmentComment,
        setAllowedAttachmentTypes,
        addImages,
        loadQstnrDoc,
        saveQstnrDoc,
        getCanvasStateAndFields,
        saveCanvasState,
        saveDataFlow,
    }
}