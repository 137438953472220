import React from 'react';
import { useAuth } from 'hooks/use-auth';
import { Link, withRouter } from 'react-router-dom';

import "./TopNav.scss";

function TopNav(props) {

    const auth = useAuth();

    const handleSignout = event => {
        event.preventDefault();
        console.log('signing out');
        auth.signout()
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark navbar-vibrant1 px-4 py-2">
        <div className="container-fluid">
            
            <Link to={"/workspaces"} className="navbar-logo mr-auto">
              {/* <h1 className="header-title">QstnAir</h1> */}
              <img src="/assets/img/logo.JPG" />
              {/* <img src="/assets/img/logo-text.JPG" /> */}
              <img src="/assets/img/logo1.JPG" />
            </Link>
            <a  href="" onClick={e=>e.preventDefault()}>

            </a>
            <div className="navbar-user">
              <div className="dropdown">
                
                <a href="#" className="avatar avatar-sm dropdown-toggle" role="button" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
                  <img src="/assets/img/avatars/profiles/avatar.jpg" alt="Default Avatar" className="avatar-img rounded-circle" />
                </a>
    
                <div className="dropdown-menu dropdown-menu-right">
                  <a href="#" className="dropdown-item">Profile</a>
                  <a href="#" className="dropdown-item">Settings</a>
                  <hr className="dropdown-divider" />
                  <a href="#" onClick={handleSignout} className="dropdown-item">Logout</a>
                </div>
              </div>
            </div>
    
        </div>
      </nav>
    );

}

export default withRouter(TopNav);