import React, { useState } from 'react';
import { Tabs, Tab, Modal } from 'react-bootstrap';
import { SwatchesPicker } from 'react-color';

import { GradientPicker } from "components/GradientPicker";

export const BGInput = (props) => {
    let { bgType, bgObject } = props;

    const getBGStyle = () => {
        if (bgType === 'color') {
            let color = bgObject.rgb;
            return { background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` };
        }
        else if (bgType === 'gradient') {
            let colors = bgObject.colors;
            return { background: `linear-gradient(to right, ${colors.join(", ")})` };
        }
    }

    return (
        <div className="bg-select-wrapper" onClick={props.isReadOnly ? undefined : props.onClick}>
            <div className={`bg-selected${props.isReadOnly?' rounded':''}`} style={getBGStyle()} />
        </div>
    )
}

export const BGPicker = (props) => {

    let { bgType, bgObject } = props;

    if (!bgType) {
        bgType = 'color';
    }
    if (!bgObject) {
        bgObject = {
            hex: "#4dd0e1",
            rgb: {
                r: "77",
                g: "208",
                b: "225",
                a: "1"
            }
        }
    }

    let tabKey = bgType === 'gradient' ? 'gradientpicker' : 'colorpicker';
    const [activeKey, setActiveKey] = useState(tabKey);

    const onSelectColor = (color) => {
        console.log(color);
        props.onSelectBG('color', color);
    }

    const onSelectGradient = (gradient) => {
        console.log('gradient2:' + gradient);
        props.onSelectBG('gradient', gradient);
    }

    return (
        <div className="bgpicker-wrapper">
            <Tabs fill defaultActiveKey={activeKey}>
                <Tab eventKey="colorpicker" title="Color Picker">
                    <SwatchesPicker
                        width={props.isModal ? '100%' : '106%'}
                        height='100%'
                        color={bgObject.rgb}
                        onChange={onSelectColor} />
                </Tab>
                <Tab eventKey="gradientpicker" title="Gradient Picker">
                    <div className="gradientpicker"><GradientPicker onChange={onSelectGradient} /></div>
                </Tab>
                <Tab eventKey="imagepicker" title="Unsplash Image">
                    <div className="imagepicker mt-2">
                        <h2>Coming soon...</h2>
                    </div>
                </Tab>
            </Tabs>
        </div>
    )
}

export const BGPickerModal = (props) => {
    return (
        <Modal
            className="bgpicker-modal"
            show={props.show}
            onHide={props.onHide}
            backdrop="static"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <BGPicker {...props} isModal={true} />
            </Modal.Body>
        </Modal >
    )
}