import React, { useState, useContext, createContext } from "react";
import { useFirebase } from 'hooks/use-firebase';
import { useAuth } from 'hooks/use-auth';

const wsContext = createContext();

export function ProvideWS({ children }) {
    const ws = useProvideWS();
    return <wsContext.Provider value={ws}>{children}</wsContext.Provider>;
}

export const useWS = () => {
    return useContext(wsContext);
};

function useProvideWS() {

    const fireContext = useFirebase();
    const { firebaseRef, fireDb } = fireContext;
    const auth = useAuth();

    let wsObj = {
        name: "",
        description: null,
        tags: []
    }

    const [workspaces, setWorkspaces] = useState([]);
    const [wsDoc, setWsDoc] = useState({ ...wsObj });
    const userProfile = JSON.parse(auth.userProfile);

    function resetWsDoc() {
        setWsDoc({ ...wsObj });
    }

    function setWSProp(obj) {
        var key = Object.keys(obj)[0];
        var value = obj[key];
        setWsDoc(prevState => {
            return { ...prevState, [key]: value }
        });
    }

    function getWorkspaces() {
        const orgId = userProfile.currentOrg;
        console.log(auth.user.uid);
        return new Promise((resolve, reject) => {
            return fireDb.collection("workspaces")
                .where("orgId", "==", orgId) //todo pra - add another where - where the current user is a reader
                .where("role_any", "array-contains", auth.user.uid)
                .get()
                .then(function (querySnapshot) {
                    var workspaces = [];
                    querySnapshot.forEach(function (doc) {
                        console.log(doc.id, " => ", doc.data());
                        if (doc.exists) {
                            workspaces.push({ ...doc.data(), id: doc.id });
                        }
                    });
                    setWorkspaces([...workspaces]);
                    resolve(true);
                })
                .catch(function (error) {
                    console.log("Error getting documents: ", error);
                    reject(error);
                });
        });
    }

    function createWorkspace() {
        var wsNew = {
            ...wsDoc,
            orgId: '7iyNwmNTFKaKGa3Yjec6',//userProfile.currentOrg,
            role_any: [auth.user.uid],
            role_owners: [auth.user.uid],
            created: firebaseRef.firestore.FieldValue.serverTimestamp(),
            updated: firebaseRef.firestore.FieldValue.serverTimestamp(),
        }
        return new Promise((resolve, reject) => {
            return fireDb.collection("workspaces").add(wsNew)
                .then(wsRef => {
                    setWorkspaces([...workspaces, { ...wsNew, id: wsRef.id }]);
                    resolve(true);
                })
                .catch(function (error) {
                    console.log("Error getting documents: ", error);
                    reject(error);
                });
        });
    }

    function updateWorkspace() {
        const { name, description, tags } = wsDoc;
        return new Promise((resolve, reject) => {
            return fireDb.collection("workspaces").doc(wsDoc.id).update({ name, description, tags })
                .then(wsRef => {
                    setWorkspaces(
                        workspaces.map(item => item.id === wsDoc.id ? wsDoc : item)
                    )
                    resolve(true);
                })
                .catch(function (error) {
                    console.log("Error getting documents: ", error);
                    reject(error);
                });
        });
    }

    function deleteWorkspace() {
        return new Promise((resolve, reject) => {
            return fireDb.collection("workspaces").doc(wsDoc.id).delete()
                .then(() => {
                    setWorkspaces(workspaces.filter(item => item.id !== wsDoc.id));
                    resolve(true);
                })
                .catch(function (error) {
                    console.log("Error deleting workspace: ", error);
                    reject(error);
                });
        });
    }

    function saveWorkspace(isNew) {
        return isNew ? createWorkspace() : updateWorkspace();
    }

    // function getWorkspace(workspaceId) {
    //     return new Promise((resolve, reject) => {
    //         console.log(workspaces.length);
    //         if (workspaces && workspaces.length > 0) {
    //             var workspace = workspaces.find(elem => elem.id === workspaceId);
    //             console.log('from cache');
    //             resolve(workspace);
    //         } else {
    //             let docRef = db.collection("workspaces").doc(workspaceId);
    //             return docRef.get().then(function (doc) {
    //                 if (doc.exists) {
    //                     console.log('from db');
    //                     resolve(doc.data());
    //                 }
    //                 reject("Error getting workspace document.");
    //             }).catch(function (error) {
    //                 console.log("Error getting workspace document:", error);
    //                 reject(error);
    //             });
    //         }
    //     });
    // }

    return {
        workspaces,
        wsDoc,
        setWsDoc,
        resetWsDoc,
        setWSProp,
        getWorkspaces,
        saveWorkspace,
        deleteWorkspace,
        //getWorkspace
    }
}