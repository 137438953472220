import React, { Fragment, useState } from 'react';
import { Form, Row, Col, Button, InputGroup, Modal, Alert } from 'react-bootstrap';
import Selectivity from 'selectivity/react';
import Toggle from 'react-toggle';
import DatePicker, { registerLocale } from "react-datepicker";
import { useDropzone } from 'react-dropzone';
import { useStudio } from 'hooks/use-studio';
import { useFireUtils } from 'hooks/use-fireutils';
import { Trash2 } from "react-feather";
import { BGPickerModal, BGInput } from "components/BGPicker";
import de from 'date-fns/locale/de';

import "react-toggle/style.css";
import "react-datepicker/dist/react-datepicker.css";

//registerLocale('de', de);

function FieldBranch(props) {
    console.log(props.choices)
    let allowedBranchTypes = props.allowedBranchTypes;
    const onChangeBranchValues = (e) => { console.log(e.value) }
    return (
        <>
            <Row><Col>Field Value</Col></Row>
            <Row key={props.id} className="row-branch">
                {
                    <>
                        <Col xs={4}>
                            <select className="form-control" defaultValue={props.branch.type} onChange={props.onChangeBranchType}>
                                {allowedBranchTypes.map(x => <option key={x.type} value={x.type}>{x.title}</option>)}
                            </select>
                        </Col>
                        <Col xs={7}>
                            {!props.allowMultiple && <input type="text" className="form-control" defaultValue={props.branch.value} onChange={props.onChangeBranchValue} />}
                            {props.allowMultiple &&
                                <Selectivity.React
                                    allowClear={true}
                                    multiple={true}
                                    items={props.choices}
                                    placeholder='Values'
                                    className="sr-wrapper"
                                    value={props.branch.valueMulti}
                                    onChange={props.onChangeBranchMultiValues}
                                />}
                        </Col>
                        <Col xs={1}>
                            <a onClick={props.onRemoveBranch}><i className="ash-2"></i></a>
                        </Col>
                    </>
                }
            </Row>
        </>
    )
}

function FieldProps(props) {
    const fireUtilsContext = useFireUtils();
    const { uploadFileToFirebase, deleteFolder, deleteFile } = fireUtilsContext;
    //const [images, setImages] = useState([]);
    const [showPicker, setShowPicker] = useState(false);
    const [bgType, setBGType] = useState('color');
    const [bgObject, setBGObject] = useState({
        rgb: {
            r: '100',
            g: '181',
            b: '246',
            a: '1',
        }
    });

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const handleCloseDelete = () => setShowDeleteModal(false);
    const handleShowDelete = () => setShowDeleteModal(true);

    const studioCxt = useStudio();
    const onChangeTitle = e => studioCxt.setTitle(e.target.value);
    const onChangeDesc = e => studioCxt.setDesc(e.target.value);
    const onChangeBG = (bgType, bgObject) => {
        studioCxt.setBGProps(bgType, bgObject);
    }
    const onChangeEmail = e => { studioCxt.setIsEmail(e.target.checked) };
    const onChangeLimited = e => { studioCxt.setIsLimited(e.target.checked) };
    const onChangeMinLength = e => studioCxt.setMinLength(e.target.value);
    const onChangeMaxLength = e => studioCxt.setMaxLength(e.target.value);
    const onChangeRanged = e => { studioCxt.setIsRanged(e.target.checked) };
    const onChangeNumberType = e => { studioCxt.setNumberType(e.value) };
    const onChangeMinValue = e => studioCxt.setMinValue(e.target.value);
    const onChangeMaxValue = e => studioCxt.setMaxValue(e.target.value);
    const onChangeMinDate = e => studioCxt.setMinDate(e);
    const onChangeMaxDate = e => studioCxt.setMaxDate(e);
    const onChangeLeftLabel = e => studioCxt.setLeftLabel(e.target.value);
    const onChangeRightLabel = e => studioCxt.setRightLabel(e.target.value);
    const onChangeMultiple = e => { studioCxt.setAllowMultiple(e.target.checked) };
    const onChangeAttachmentComment = e => { studioCxt.setAllowAttachmentComment(e.target.checked) };
    const onChangeAttachmentType = e => { console.log(e.target.id, e.target.checked); studioCxt.setAllowedAttachmentTypes(e.target.id, e.target.checked) };
    const onAddChoice = e => studioCxt.addChoice();
    const onChangeChoiceValue = (index) => (e) => { studioCxt.setChoiceValue(index, e.target.value) };
    const onChangeChoiceText = (index) => (e) => { studioCxt.setChoiceText(index, e.target.value) };
    const onRemoveChoice = (index) => (e) => { studioCxt.removeChoice(index) };
    const onRemoveImageChoice = (index, fileName) => (e) => {
        const field = studioCxt.activeField;
        const folderPath = `images/qstnairs/${props.qstnrId}/${field.id}`;
        deleteFile(folderPath, fileName);
        studioCxt.removeImageChoice(index);
    };
    const onChangeImageCaption = (index) => (e) => { studioCxt.setImageCaption(index, e.target.value) };
    const onRemoveField = e => {
        const field = studioCxt.activeField;
        if (field.type === 'imagechoice') {
            console.log('removing images');
            const folderPath = `images/qstnairs/${props.qstnrId}/${field.id}`;
            deleteFolder(folderPath);
        }
        studioCxt.removeField(studioCxt.activeField.id);
        setShowDeleteModal(false);
    };
    const onChangeBranching = e => { studioCxt.setBranching(e.target.checked) };
    const onChangeRequired = e => { studioCxt.setIsRequired(e.target.checked) };
    const onChangeRatingIconType = e => { studioCxt.setRatingIconType(e.value) };
    const onChangeRatingScale = e => { studioCxt.setRatingScale(e.value) };
    const onChangeRatingHalfAllowed = e => { studioCxt.setRatingHalfAllowed(e.target.checked) };
    const onChangeFileUpload = e => { console.log('uploading file...') };
    const onAddBranch = e => studioCxt.addBranch();
    const onChangeBranchType = (index) => (e) => { studioCxt.setBranchType(index, e.target.value) };
    const onChangeBranchValue = (index) => (e) => { studioCxt.setBranchValue(index, e.target.value) };
    const onRemoveBranch = (index) => (e) => { studioCxt.removeBranch(index) };
    const onChangeCheck = (e) => { console.log(e.target.id); }

    const renderTextUI = () => {
        return (
            <>
                {
                    studioCxt.activeField.type === 'text' &&
                    <Row>
                        <Col xs={8} className='align-self-center'>
                            Is Email field?
                    </Col>
                        <Col xs={4}>
                            <Toggle
                                checked={studioCxt.activeField.isEmail}
                                aria-label='Is Email field?'
                                onChange={onChangeEmail} />
                        </Col>
                    </Row>
                }
                <Row>
                    <Col xs={8} className='align-self-center'>
                        Enforce length of characters?
                    </Col>
                    <Col xs={4}>
                        <Toggle
                            checked={studioCxt.activeField.isLimited}
                            aria-label='Enforce length of characters?'
                            onChange={onChangeLimited} />
                    </Col>
                </Row>
                {
                    studioCxt.activeField.isLimited &&
                    <Row>
                        <Col xs={6}>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="igLenMin">Minimum Length</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control value={studioCxt.activeField.minLength} onChange={onChangeMinLength} />
                            </InputGroup>
                        </Col>
                        <Col xs={6}>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="igLenMax">Maximum Length</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control value={studioCxt.activeField.maxLength} onChange={onChangeMaxLength} />
                            </InputGroup>
                        </Col>
                    </Row>
                }
            </>
        )
    }

    const renderRangeUI = () => {
        return (
            <>
                {
                    studioCxt.activeField.type === 'number' &&
                    <Row>
                        <Col xs={8} className='align-self-center'>
                            Number Type
                        </Col>
                        <Col xs={4}>
                            <Selectivity.React
                                allowClear={false}
                                items={studioCxt.numberTypes.map(x => ({ id: x.type, text: x.title }))}
                                value={studioCxt.activeField.numberType}
                                onChange={onChangeNumberType}
                                className="sr-wrapper"
                            />
                        </Col>
                    </Row>
                }
                <Row>
                    <Col xs={8} className='align-self-center'>
                        Enforce Range?
                    </Col>
                    <Col xs={4}>
                        <Toggle
                            checked={studioCxt.activeField.isRanged}
                            aria-label='Enforce Range?'
                            onChange={onChangeRanged} />
                    </Col>
                </Row>
                {
                    studioCxt.activeField.isRanged &&
                    <>
                        {
                            ['date', 'daterange'].includes(studioCxt.activeField.type) ?
                                <Row>
                                    <Col xs={6}>
                                        <DatePicker dateFormat="dd.MM.yyyy" showPopperArrow={false} placeholderText="Minimum"
                                            selected={studioCxt.activeField.minDate} onChange={onChangeMinDate} className="form-control"
                                            peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                    </Col>
                                    <Col xs={6}>
                                        <DatePicker dateFormat="dd.MM.yyyy" showPopperArrow={false} placeholderText="Maximum"
                                            selected={studioCxt.activeField.maxDate} onChange={onChangeMaxDate} className="form-control"
                                            peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select"
                                            popperPlacement="bottom-end" />
                                    </Col>
                                </Row>
                                :
                                <Row>
                                    <Col xs={6}>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="igRangeMin">Minimum</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control value={studioCxt.activeField.minValue} onChange={onChangeMinValue} />
                                        </InputGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="igRangeMax">Maximum</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control value={studioCxt.activeField.maxValue} onChange={onChangeMaxValue} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                        }
                    </>
                }
            </>
        )
    }

    const renderNPSUI = () => {
        return (
            <>
                <Form.Group controlId="npsLeft">
                    <Form.Label>Left</Form.Label>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="igNpsLeft">0</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control value={studioCxt.activeField.leftLabel} onChange={onChangeLeftLabel} />
                    </InputGroup>
                </Form.Group>

                <Form.Group controlId="npsRight">
                    <Form.Label>Right</Form.Label>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="igNpsRight">10</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control value={studioCxt.activeField.rightLabel} onChange={onChangeRightLabel} />
                    </InputGroup>
                </Form.Group>
            </>
        )
    }

    // getCurrentRatingType(type) {
    //     const store = this.props.designerStore;
    //     let result = [];
    //     if (store.activeField && store.activeField.iconType) {
    //         result = store.ratingIcons.filter(x => x.dataTypes.includes(store.activeField.dataType));
    //     }
    //     return result;
    // }

    const isHalfRatingDisabled = () => {
        const iconType = studioCxt.activeField.ratingIconType;
        return !studioCxt.ratingIcons.find(x => x.type === iconType).isHalfAllowed;
    }

    const renderRatingUI = () => {
        return (
            <Fragment key={studioCxt.activeField.id}>
                <Row>
                    <Col xs={8}>
                        Rating Icon
                    </Col>
                    <Col xs={4}>
                        <Selectivity.React
                            allowClear={false}
                            items={studioCxt.ratingIcons.map(x => ({ id: x.type, text: x.title }))}
                            value={studioCxt.activeField.ratingIconType}
                            onChange={onChangeRatingIconType}
                            className="sr-wrapper"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={8}>
                        Scale
                    </Col>
                    <Col xs={4}>
                        <Selectivity.React
                            allowClear={false}
                            items={[2, 3, 4, 5, 6, 7]}
                            value={studioCxt.activeField.ratingScale}
                            onChange={onChangeRatingScale}
                            className="sr-wrapper"
                        />
                    </Col>
                </Row>
                {/* <Row>
                    <Col xs={8}>
                        Enable half-rating?
                    </Col>
                    <Col xs={4}>
                        <Toggle
                            disabled={isHalfRatingDisabled()}
                            checked={studioCxt.activeField.ratingHalfAllowed}
                            aria-label='Enable branching on choices?'
                            onChange={onChangeRatingHalfAllowed} />
                    </Col>
                </Row> */}
            </Fragment>
        )
    }

    const renderImageChoices = () => {
        const charArr = 'ABCDEFGHIJ'.split('');
        const thumb = {
            display: 'flex',
            borderRadius: 8,
            border: '1px solid #B1C2D9',
            width: 80,
            height: 80,
            marginBottom: '4px',
            padding: 2,
            overflow: 'hidden',
        };
        const img = {
            maxWidth: '100%',
            display: 'block',
            height: 'auto',
            objectFit: 'contain'
        };

        let images = studioCxt.activeField.images;
        return images.map((image, i) => {
            return (
                <Row key={image.name}>
                    <Col xs={1}>
                        {charArr[i]})
                    </Col>
                    <Col xs={3} key={image.name} >
                        <div style={thumb}>
                            <img
                                src={image.url}
                                style={img}
                            />
                        </div>
                    </Col>
                    <Col xs={7}>
                        <input type="text" className="form-control" value={image.caption} onChange={onChangeImageCaption(i)} />
                    </Col>
                    <Col xs={1}>
                        <a href="#" onClick={onRemoveImageChoice(i, image.name)}><Trash2 size={16} /></a>
                    </Col>
                </Row >
            )
        });
    }

    const ImageUploader = (props) => {
        const maxSize = 1048576;
        const { images, addImages, qstnrId, fieldId } = props;

        const dropStyle = {
            height: 100,
            color: '#fff',
            backgroundColor: '#00D97E',
            borderRadius: 4,
            border: '1px solid #00D97E',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }

        const { getRootProps, getInputProps, open, isDragActive, isDragAccept, isDragReject, acceptedFiles, fileRejections } = useDropzone({
            accept: 'image/*',
            minSize: 0,
            maxSize: maxSize,
            multiple: true,
            noClick: true,
            noKeyboard: true,
            onDrop: acceptedFiles => {
                console.log('whatevereeeee');
                if (acceptedFiles.length == 0) {
                    console.log('please select at least one...');
                    return;
                }
                else if (images.length + acceptedFiles.length > 5) {
                    console.log('max 5 allowed...');
                    return;
                }
                else {
                    var promises = [], newImages = [];
                    acceptedFiles.forEach(file => {
                        promises.push(
                            uploadFileToFirebase(qstnrId, fieldId, file, { path: file.path, size: file.size, type: file.type })
                                .then((fileUrl) => {
                                    var newImage = Object.assign(file, { url: fileUrl });
                                    newImages.push(newImage);
                                })
                                .catch((errorMsg) => {
                                    console.log(errorMsg);
                                })
                        );
                    });
                    Promise.all(promises).then(() => {
                        addImages(newImages);
                    })
                }
            }
        });

        //const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].size > maxSize;

        // const rejectedFilesItems = fileRejections.map(file => (
        //     <li key={file.path}>
        //       {file.path} - {file.size} bytes
        //     </li>
        //   ));

        // const thumbs = images.map((image, i) => {
        //     console.log(image);
        //     return (
        //         <Row key={image.name}>
        //             <Col xs={3} key={image.name} >
        //                 <div style={thumb}>
        //                     <img
        //                         src={image.url}
        //                         style={img}
        //                     />
        //                 </div>
        //             </Col>
        //             <Col xs={8}>
        //                 <input type="text" className="form-control" value={image.caption} onChange={onChangeImageCaption(i)} />
        //             </Col>
        //             <Col xs={1}>
        //                 <a href="#" onClick={onRemoveImageChoice(i, image.name)}><i className="fe fe-trash-2"></i></a>
        //             </Col>
        //         </Row >
        //     )
        // });

        // useEffect(() => () => {
        //     // Make sure to revoke the data uris to avoid memory leaks
        //     files.forEach(file => URL.revokeObjectURL(file.preview));
        // }, [files]);

        return (
            <>
                <Row>
                    <Col xs={12}>
                        {
                            images.length < 5 &&
                            <div style={dropStyle} {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                {!isDragActive && 'Drag n drop image files here!'}
                                {isDragActive && !isDragReject && "Drop here!"}
                                <button type="button" className="btn btn-sm btn-primary mt-2" onClick={open}>
                                    Open image picker
                                </button>
                                {/* {isDragReject && "File type not accepted, sorry!"}
                                <h4>rejected::</h4>
                                {fileRejections.length > 0 ? <div className="alert alert-danger" role="alert">
                                    {fileRejections[0].errors[0].message}
                                </div> : null} */}
                            </div>
                        }
                    </Col>
                </Row>
            </>
        );
    }

    const renderAttachUI = () => {
        return (
            //https://react-bootstrap.github.io/components/forms/#forms-form-check
            <>
                <Row>
                    <Col xs={8} className='align-self-center'>
                        Show Comment Box?
                    </Col>
                    <Col xs={4}>
                        <Toggle
                            checked={studioCxt.activeField.allowComment}
                            aria-label='Show Comment Box?'
                            onChange={onChangeAttachmentComment} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Group controlId="attachTypes">
                            <Form.Label>Allowed attachment types:</Form.Label>
                            <Form.Check id="pdf" checked={studioCxt.activeField.allowedFileTypes.includes('pdf')} label="Document (.pdf)" onChange={onChangeAttachmentType} />
                            <Form.Check id="word" checked={studioCxt.activeField.allowedFileTypes.includes('word')} label="Word Document (.docx/.doc)" onChange={onChangeAttachmentType} />
                            <Form.Check id="image" checked={studioCxt.activeField.allowedFileTypes.includes('image')} label="Image (.jpg/.jpeg/.png/.gif)" onChange={onChangeAttachmentType} />
                            <Form.Check id="excel" checked={studioCxt.activeField.allowedFileTypes.includes('excel')} label="Excel (.xls/.xlsx)" onChange={onChangeAttachmentType} />
                            <Form.Check id="audio" checked={studioCxt.activeField.allowedFileTypes.includes('audio')} label="Audio (.mp3/.wav) **" onChange={onChangeAttachmentType} />
                            <Form.Check id="video" checked={studioCxt.activeField.allowedFileTypes.includes('video')} label="Video (.mp4) **" onChange={onChangeAttachmentType} />
                        </Form.Group>
                    </Col>
                </Row>
            </>
        )
    }

    const renderBranchingUI = () => {
        let branchOnChoice = studioCxt.activeField.hasChoices && !studioCxt.activeField.allowMultiple;
        let label = branchOnChoice ? 'Enable branching on choices?' : 'Enable branching?';
        return (
            <>
                <hr />
                <Row>
                    <Col xs={8}>
                        {label}
                    </Col>
                    <Col xs={4}>
                        <Toggle
                            disabled={!studioCxt.activeField.canBranch}
                            checked={studioCxt.activeField.isBranchingEnabled}
                            aria-label={label}
                            onChange={onChangeBranching} />
                    </Col>
                </Row>
            </>
        )
    }

    const renderBGPickerUI = () => {
        const handleClick = () => {
            setShowPicker(!showPicker);
        };

        const onSelectBG = (bgType, bgObject) => {
            onChangeBG(bgType, bgObject);
            // setBGType(bgType);
            // setBGObject({ ...bgObject });
            window.setTimeout(() => setShowPicker(false), 250);
        }

        const onHideBGModal = () => {
            setShowPicker(false);
        }

        return (
            <>
                <hr />
                <Row>
                    <Col xs={8}>
                        Choose a background for the slide
                    </Col>
                    <Col xs={4}>
                        <BGInput bgType={studioCxt.activeField.bgType} bgObject={studioCxt.activeField.bgObject} onClick={handleClick} />
                    </Col>
                </Row>
                <BGPickerModal show={showPicker} bgType={studioCxt.activeField.bgType} bgObject={studioCxt.activeField.bgObject} onSelectBG={onSelectBG} onHide={onHideBGModal} />
            </>
        )
    }

    const getAllowedBranchTypes = () => {
        let result = [];
        if (studioCxt.activeField) {
            //result = store.branchTypes.filter(x => (x.dataTypes.length === 0 || x.dataTypes.includes(store.activeField.dataType)));
            result = studioCxt.branchTypes[studioCxt.activeField.dataType];
        }
        return result;
    }

    const allowedBranchTypes = getAllowedBranchTypes();
    const [showAlert, setShowAlert] = useState(true);

    return (

        !studioCxt.activeField ? null :
            <div key={studioCxt.activeField.id}>
                <Row>
                    <Col xs={12}>
                        <h3>{studioCxt.activeField.typeLabel} / {studioCxt.activeField.type} / {studioCxt.activeField.id}</h3>
                    </Col>
                </Row>

                {
                    studioCxt.activeField.errors &&
                    <Row>
                        <Col xs={12}>
                            <Alert show={studioCxt.activeField.errors.length} variant={'danger'}>
                                {studioCxt.activeField.errors.map(item => <>- {item}<br /></>)}
                            </Alert>
                        </Col>
                    </Row>
                }

                <Form.Group controlId="fieldTitle">
                    <Form.Label>Title</Form.Label>
                    <Form.Control value={studioCxt.activeField.title} onChange={onChangeTitle} />
                </Form.Group>
                <Form.Group controlId="fieldDesc">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" value={studioCxt.activeField.desc} onChange={onChangeDesc} />
                </Form.Group>

                {
                    ['start', 'end'].includes(studioCxt.activeField.type) && renderBGPickerUI()
                }

                <Row>
                    <Col xs={8}>
                        Is Field Mandatory?
                    </Col>
                    <Col xs={4}>
                        <Toggle
                            disabled={studioCxt.activeField.isBranchingEnabled || ['start', 'end'].includes(studioCxt.activeField.type)}
                            checked={studioCxt.activeField.isRequired}
                            aria-label='Is Field Mandatory?'
                            onChange={onChangeRequired} />
                    </Col>
                </Row>

                {
                    ['text', 'textarea'].includes(studioCxt.activeField.type) && renderTextUI()
                }

                {
                    studioCxt.activeField.canRange && renderRangeUI()
                }

                {
                    studioCxt.activeField.type === 'nps' && renderNPSUI()
                }

                {
                    studioCxt.activeField.type === 'starrating' && renderRatingUI()
                }

                {
                    studioCxt.activeField.hasChoices && studioCxt.activeField.type !== 'imagechoice' &&
                    <>
                        <hr />
                        {studioCxt.activeField.hasChoices && studioCxt.activeField.choices.map((opt, i) =>
                            <Row key={opt.id}>
                                {/* <Col xs={5}>
                                    <input type="text" className="form-control" value={opt.value} onChange={onChangeChoiceValue(i)} />
                                </Col> */}
                                <Col xs={10}>
                                    <input type="text" className="form-control" value={opt.text} onChange={onChangeChoiceText(i)} />
                                </Col>
                                <Col xs={2}>
                                    <a href="#" onClick={onRemoveChoice(i)}><Trash2 size={16} /></a>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col xs={12}>
                                <Button color="primary" onClick={onAddChoice}>Add Choice</Button>
                            </Col>
                        </Row>
                    </>
                }

                {
                    studioCxt.activeField.type === 'imagechoice' && renderImageChoices()
                }

                {
                    studioCxt.activeField.type === 'imagechoice' &&
                    <ImageUploader key={'iu_' + studioCxt.activeField.id}
                        images={studioCxt.activeField.images}
                        addImages={studioCxt.addImages}
                        qstnrId={props.qstnrId}
                        fieldId={studioCxt.activeField.id} />
                }

                {
                    studioCxt.activeField.type === 'attachment' && renderAttachUI()
                }

                {
                    studioCxt.activeField.canMultiple &&
                    <>
                        <hr></hr>
                        <Row>
                            <Col xs={8} className='align-self-center'>
                                Allow multiple?
                            </Col>
                            <Col xs={4}>
                                <Toggle
                                    checked={studioCxt.activeField.allowMultiple}
                                    aria-label='Allow multiple?'
                                    onChange={onChangeMultiple} />
                            </Col>
                        </Row>
                    </>
                }

                {renderBranchingUI()}

                {
                    !studioCxt.activeField.isBranchingEnabled ? null :
                        <>
                            {studioCxt.activeField.branches.map((branch, i) =>
                                <FieldBranch key={i} id={branch.id} branch={branch} fieldType={studioCxt.activeField.type}
                                    allowedBranchTypes={allowedBranchTypes}
                                    onChangeBranchType={onChangeBranchType(i)}
                                    onChangeBranchValue={onChangeBranchValue(i)}
                                    choices={studioCxt.activeField.type === 'imagechoice' ? studioCxt.activeField.images : studioCxt.activeField.choices}
                                    allowMultiple={studioCxt.activeField.allowMultiple}
                                    onRemoveBranch={onRemoveBranch(i)} />
                            )}
                            {(!studioCxt.activeField.hasChoices || studioCxt.activeField.allowMultiple) && <Button variant="primary" onClick={onAddBranch}>Add Branch</Button>}
                        </>
                }

                <hr />
                <Button variant="danger" onClick={handleShowDelete}>Remove Field</Button>

                <Modal show={showDeleteModal} onHide={handleCloseDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete}>Close</Button>
                        <Button variant="danger" onClick={onRemoveField}>Delete</Button>
                    </Modal.Footer>
                </Modal>

            </div >
    );
}


export default FieldProps;
