class Utils {
    static getUniqueId() {
        return Math.random().toString(36).substring(2, 8);
    }
    static pluck(array, key) {
        return array.map(o => o[key]);
    }
    static getLanguages(filteredIds) {
        const all = [
                //{ "code": "ab", "name": "Abkhaz", "nativeName": "аҧсуа" },
                { "id": "ab", "name": "Abkhaz", "text": "аҧсуа" },
                { "id": "aa", "name": "Afar", "text": "Afaraf" },
                { "id": "af", "name": "Afrikaans", "text": "Afrikaans" },
                { "id": "ak", "name": "Akan", "text": "Akan" },
                { "id": "sq", "name": "Albanian", "text": "Shqip" },
                { "id": "am", "name": "Amharic", "text": "አማርኛ" },
                { "id": "ar", "name": "Arabic", "text": "العربية" },
                { "id": "an", "name": "Aragonese", "text": "Aragonés" },
                { "id": "hy", "name": "Armenian", "text": "Հայերեն" },
                { "id": "as", "name": "Assamese", "text": "অসমীয়া" },
                { "id": "av", "name": "Avaric", "text": "авар мацӀ, магӀарул мацӀ" },
                { "id": "ae", "name": "Avestan", "text": "avesta" },
                { "id": "ay", "name": "Aymara", "text": "aymar aru" },
                { "id": "az", "name": "Azerbaijani", "text": "azərbaycan dili" },
                { "id": "bm", "name": "Bambara", "text": "bamanankan" },
                { "id": "ba", "name": "Bashkir", "text": "башҡорт теле" },
                { "id": "eu", "name": "Basque", "text": "euskara, euskera" },
                { "id": "be", "name": "Belarusian", "text": "Беларуская" },
                { "id": "bn", "name": "Bengali", "text": "বাংলা" },
                { "id": "bh", "name": "Bihari", "text": "भोजपुरी" },
                { "id": "bi", "name": "Bislama", "text": "Bislama" },
                { "id": "bs", "name": "Bosnian", "text": "bosanski jezik" },
                { "id": "br", "name": "Breton", "text": "brezhoneg" },
                { "id": "bg", "name": "Bulgarian", "text": "български език" },
                { "id": "my", "name": "Burmese", "text": "ဗမာစာ" },
                { "id": "ca", "name": "Catalan; Valencian", "text": "Català" },
                { "id": "ch", "name": "Chamorro", "text": "Chamoru" },
                { "id": "ce", "name": "Chechen", "text": "нохчийн мотт" },
                { "id": "ny", "name": "Chichewa; Chewa; Nyanja", "text": "chiCheŵa, chinyanja" },
                { "id": "zh", "name": "Chinese", "text": "中文 (Zhōngwén), 汉语, 漢語" },
                { "id": "cv", "name": "Chuvash", "text": "чӑваш чӗлхи" },
                { "id": "kw", "name": "Cornish", "text": "Kernewek" },
                { "id": "co", "name": "Corsican", "text": "corsu, lingua corsa" },
                { "id": "cr", "name": "Cree", "text": "ᓀᐦᐃᔭᐍᐏᐣ" },
                { "id": "hr", "name": "Croatian", "text": "hrvatski" },
                { "id": "cs", "name": "Czech", "text": "česky, čeština" },
                { "id": "da", "name": "Danish", "text": "dansk" },
                { "id": "dv", "name": "Divehi; Dhivehi; Maldivian;", "text": "ދިވެހި" },
                { "id": "nl", "name": "Dutch", "text": "Nederlands, Vlaams" },
                { "id": "en", "name": "English", "text": "English" },
                { "id": "eo", "name": "Esperanto", "text": "Esperanto" },
                { "id": "et", "name": "Estonian", "text": "eesti, eesti keel" },
                { "id": "ee", "name": "Ewe", "text": "Eʋegbe" },
                { "id": "fo", "name": "Faroese", "text": "føroyskt" },
                { "id": "fj", "name": "Fijian", "text": "vosa Vakaviti" },
                { "id": "fi", "name": "Finnish", "text": "suomi, suomen kieli" },
                { "id": "fr", "name": "French", "text": "français, langue française" },
                { "id": "ff", "name": "Fula; Fulah; Pulaar; Pular", "text": "Fulfulde, Pulaar, Pular" },
                { "id": "gl", "name": "Galician", "text": "Galego" },
                { "id": "ka", "name": "Georgian", "text": "ქართული" },
                { "id": "de", "name": "German", "text": "Deutsch" },
                { "id": "el", "name": "Greek, Modern", "text": "Ελληνικά" },
                { "id": "gn", "name": "Guaraní", "text": "Avañeẽ" },
                { "id": "gu", "name": "Gujarati", "text": "ગુજરાતી" },
                { "id": "ht", "name": "Haitian; Haitian Creole", "text": "Kreyòl ayisyen" },
                { "id": "ha", "name": "Hausa", "text": "Hausa, هَوُسَ" },
                { "id": "he", "name": "Hebrew (modern)", "text": "עברית" },
                { "id": "hz", "name": "Herero", "text": "Otjiherero" },
                { "id": "hi", "name": "Hindi", "text": "हिन्दी, हिंदी" },
                { "id": "ho", "name": "Hiri Motu", "text": "Hiri Motu" },
                { "id": "hu", "name": "Hungarian", "text": "Magyar" },
                { "id": "ia", "name": "Interlingua", "text": "Interlingua" },
                { "id": "id", "name": "Indonesian", "text": "Bahasa Indonesia" },
                { "id": "ie", "name": "Interlingue", "text": "Originally called Occidental; then Interlingue after WWII" },
                { "id": "ga", "name": "Irish", "text": "Gaeilge" },
                { "id": "ig", "name": "Igbo", "text": "Asụsụ Igbo" },
                { "id": "ik", "name": "Inupiaq", "text": "Iñupiaq, Iñupiatun" },
                { "id": "io", "name": "Ido", "text": "Ido" },
                { "id": "is", "name": "Icelandic", "text": "Íslenska" },
                { "id": "it", "name": "Italian", "text": "Italiano" },
                { "id": "iu", "name": "Inuktitut", "text": "ᐃᓄᒃᑎᑐᑦ" },
                { "id": "ja", "name": "Japanese", "text": "日本語 (にほんご／にっぽんご)" },
                { "id": "jv", "name": "Javanese", "text": "basa Jawa" },
                { "id": "kl", "name": "Kalaallisut, Greenlandic", "text": "kalaallisut, kalaallit oqaasii" },
                { "id": "kn", "name": "Kannada", "text": "ಕನ್ನಡ" },
                { "id": "kr", "name": "Kanuri", "text": "Kanuri" },
                { "id": "ks", "name": "Kashmiri", "text": "कश्मीरी, كشميري‎" },
                { "id": "kk", "name": "Kazakh", "text": "Қазақ тілі" },
                { "id": "km", "name": "Khmer", "text": "ភាសាខ្មែរ" },
                { "id": "ki", "name": "Kikuyu, Gikuyu", "text": "Gĩkũyũ" },
                { "id": "rw", "name": "Kinyarwanda", "text": "Ikinyarwanda" },
                { "id": "ky", "name": "Kirghiz, Kyrgyz", "text": "кыргыз тили" },
                { "id": "kv", "name": "Komi", "text": "коми кыв" },
                { "id": "kg", "name": "Kongo", "text": "KiKongo" },
                { "id": "ko", "name": "Korean", "text": "한국어 (韓國語), 조선말 (朝鮮語)" },
                { "id": "ku", "name": "Kurdish", "text": "Kurdî, كوردی‎" },
                { "id": "kj", "name": "Kwanyama, Kuanyama", "text": "Kuanyama" },
                { "id": "la", "name": "Latin", "text": "latine, lingua latina" },
                { "id": "lb", "name": "Luxembourgish, Letzeburgesch", "text": "Lëtzebuergesch" },
                { "id": "lg", "name": "Luganda", "text": "Luganda" },
                { "id": "li", "name": "Limburgish, Limburgan, Limburger", "text": "Limburgs" },
                { "id": "ln", "name": "Lingala", "text": "Lingála" },
                { "id": "lo", "name": "Lao", "text": "ພາສາລາວ" },
                { "id": "lt", "name": "Lithuanian", "text": "lietuvių kalba" },
                { "id": "lu", "name": "Luba-Katanga", "text": "" },
                { "id": "lv", "name": "Latvian", "text": "latviešu valoda" },
                { "id": "gv", "name": "Manx", "text": "Gaelg, Gailck" },
                { "id": "mk", "name": "Macedonian", "text": "македонски јазик" },
                { "id": "mg", "name": "Malagasy", "text": "Malagasy fiteny" },
                { "id": "ms", "name": "Malay", "text": "bahasa Melayu, بهاس ملايو‎" },
                { "id": "ml", "name": "Malayalam", "text": "മലയാളം" },
                { "id": "mt", "name": "Maltese", "text": "Malti" },
                { "id": "mi", "name": "Māori", "text": "te reo Māori" },
                { "id": "mr", "name": "Marathi (Marāṭhī)", "text": "मराठी" },
                { "id": "mh", "name": "Marshallese", "text": "Kajin M̧ajeļ" },
                { "id": "mn", "name": "Mongolian", "text": "монгол" },
                { "id": "na", "name": "Nauru", "text": "Ekakairũ Naoero" },
                { "id": "nv", "name": "Navajo, Navaho", "text": "Diné bizaad, Dinékʼehǰí" },
                { "id": "nb", "name": "Norwegian Bokmål", "text": "Norsk bokmål" },
                { "id": "nd", "name": "North Ndebele", "text": "isiNdebele" },
                { "id": "ne", "name": "Nepali", "text": "नेपाली" },
                { "id": "ng", "name": "Ndonga", "text": "Owambo" },
                { "id": "nn", "name": "Norwegian Nynorsk", "text": "Norsk nynorsk" },
                { "id": "no", "name": "Norwegian", "text": "Norsk" },
                { "id": "ii", "name": "Nuosu", "text": "ꆈꌠ꒿ Nuosuhxop" },
                { "id": "nr", "name": "South Ndebele", "text": "isiNdebele" },
                { "id": "oc", "name": "Occitan", "text": "Occitan" },
                { "id": "oj", "name": "Ojibwe, Ojibwa", "text": "ᐊᓂᔑᓈᐯᒧᐎᓐ" },
                { "id": "cu", "name": "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic", "text": "ѩзыкъ словѣньскъ" },
                { "id": "om", "name": "Oromo", "text": "Afaan Oromoo" },
                { "id": "or", "name": "Oriya", "text": "ଓଡ଼ିଆ" },
                { "id": "os", "name": "Ossetian, Ossetic", "text": "ирон æвзаг" },
                { "id": "pa", "name": "Panjabi, Punjabi", "text": "ਪੰਜਾਬੀ, پنجابی‎" },
                { "id": "pi", "name": "Pāli", "text": "पाऴि" },
                { "id": "fa", "name": "Persian", "text": "فارسی" },
                { "id": "pl", "name": "Polish", "text": "polski" },
                { "id": "ps", "name": "Pashto, Pushto", "text": "پښتو" },
                { "id": "pt", "name": "Portuguese", "text": "Português" },
                { "id": "qu", "name": "Quechua", "text": "Runa Simi, Kichwa" },
                { "id": "rm", "name": "Romansh", "text": "rumantsch grischun" },
                { "id": "rn", "name": "Kirundi", "text": "kiRundi" },
                { "id": "ro", "name": "Romanian, Moldavian, Moldovan", "text": "română" },
                { "id": "ru", "name": "Russian", "text": "русский язык" },
                { "id": "sa", "name": "Sanskrit (Saṁskṛta)", "text": "संस्कृतम्" },
                { "id": "sc", "name": "Sardinian", "text": "sardu" },
                { "id": "sd", "name": "Sindhi", "text": "सिन्धी, سنڌي، سندھی‎" },
                { "id": "se", "name": "Northern Sami", "text": "Davvisámegiella" },
                { "id": "sm", "name": "Samoan", "text": "gagana faa Samoa" },
                { "id": "sg", "name": "Sango", "text": "yângâ tî sängö" },
                { "id": "sr", "name": "Serbian", "text": "српски језик" },
                { "id": "gd", "name": "Scottish Gaelic; Gaelic", "text": "Gàidhlig" },
                { "id": "sn", "name": "Shona", "text": "chiShona" },
                { "id": "si", "name": "Sinhala, Sinhalese", "text": "සිංහල" },
                { "id": "sk", "name": "Slovak", "text": "slovenčina" },
                { "id": "sl", "name": "Slovene", "text": "slovenščina" },
                { "id": "so", "name": "Somali", "text": "Soomaaliga, af Soomaali" },
                { "id": "st", "name": "Southern Sotho", "text": "Sesotho" },
                { "id": "es", "name": "Spanish; Castilian", "text": "español, castellano" },
                { "id": "su", "name": "Sundanese", "text": "Basa Sunda" },
                { "id": "sw", "name": "Swahili", "text": "Kiswahili" },
                { "id": "ss", "name": "Swati", "text": "SiSwati" },
                { "id": "sv", "name": "Swedish", "text": "svenska" },
                { "id": "ta", "name": "Tamil", "text": "தமிழ்" },
                { "id": "te", "name": "Telugu", "text": "తెలుగు" },
                { "id": "tg", "name": "Tajik", "text": "тоҷикӣ, toğikī, تاجیکی‎" },
                { "id": "th", "name": "Thai", "text": "ไทย" },
                { "id": "ti", "name": "Tigrinya", "text": "ትግርኛ" },
                { "id": "bo", "name": "Tibetan Standard, Tibetan, Central", "text": "བོད་ཡིག" },
                { "id": "tk", "name": "Turkmen", "text": "Türkmen, Түркмен" },
                { "id": "tl", "name": "Tagalog", "text": "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔" },
                { "id": "tn", "name": "Tswana", "text": "Setswana" },
                { "id": "to", "name": "Tonga (Tonga Islands)", "text": "faka Tonga" },
                { "id": "tr", "name": "Turkish", "text": "Türkçe" },
                { "id": "ts", "name": "Tsonga", "text": "Xitsonga" },
                { "id": "tt", "name": "Tatar", "text": "татарча, tatarça, تاتارچا‎" },
                { "id": "tw", "name": "Twi", "text": "Twi" },
                { "id": "ty", "name": "Tahitian", "text": "Reo Tahiti" },
                { "id": "ug", "name": "Uighur, Uyghur", "text": "Uyƣurqə, ئۇيغۇرچە‎" },
                { "id": "uk", "name": "Ukrainian", "text": "українська" },
                { "id": "ur", "name": "Urdu", "text": "اردو" },
                { "id": "uz", "name": "Uzbek", "text": "zbek, Ўзбек, أۇزبېك‎" },
                { "id": "ve", "name": "Venda", "text": "Tshivenḓa" },
                { "id": "vi", "name": "Vietnamese", "text": "Tiếng Việt" },
                { "id": "vo", "name": "Volapük", "text": "Volapük" },
                { "id": "wa", "name": "Walloon", "text": "Walon" },
                { "id": "cy", "name": "Welsh", "text": "Cymraeg" },
                { "id": "wo", "name": "Wolof", "text": "Wollof" },
                { "id": "fy", "name": "Western Frisian", "text": "Frysk" },
                { "id": "xh", "name": "Xhosa", "text": "isiXhosa" },
                { "id": "yi", "name": "Yiddish", "text": "ייִדיש" },
                { "id": "yo", "name": "Yoruba", "text": "Yorùbá" },
                { "id": "za", "name": "Zhuang, Chuang", "text": "Saɯ cueŋƅ, Saw cuengh" }
            ]
        if(!filteredIds) return all;
        return all.filter(item => filteredIds.includes(item.id))
    }
}

export default Utils;