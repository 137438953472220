import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import ReactTagInput from "@pathofdev/react-tag-input";

import { useWS } from 'hooks/use-ws.js';
import "components/ReactTagInput.scss";

function EditWorkspaceModal(props) {
    console.log('rendering workspace...');
    const wsCxt = useWS();
    const isNew = !wsCxt.wsDoc.id;
    
    const [validated, setValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertVariant, setAlertVariant] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const handleError = err => {
        setAlertVariant('danger');
        setAlertMessage(err.message);
        setShowAlert(true);
    }

    const onChangeName = e => wsCxt.setWSProp({ name: e.target.value });
    const onChangeDesc = e => wsCxt.setWSProp({ description: e.target.value });
    const onChangeTags = newTags => wsCxt.setWSProp({ tags: newTags });

    const handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            wsCxt.saveWorkspace(isNew).then(() => {
                console.log('saved...');
                props.onHide(true);
            })
            .catch(handleError)
        }
    }

    useEffect(() => {
        if (showAlert) {
          window.setTimeout(() => setShowAlert(false), 4000);
        }
      }, [showAlert]);

    useEffect(() => {
        function onInit() {
            console.log('initing it...')
            wsCxt.resetWsDoc();
        }
        onInit();
    }, []);

    return (

        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {isNew ? 'Create New Workspace' : 'Edit Workspace'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="fgTitle">
                        <Form.Label>Name</Form.Label>
                        <Form.Control required type="text" placeholder="Name" value={wsCxt.wsDoc.name} onChange={onChangeName} />
                    </Form.Group>
                    <Form.Group controlId="fieldDesc">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" value={wsCxt.wsDoc.description} onChange={onChangeDesc} />
                    </Form.Group>
                    <Form.Group controlId="fieldDesc">
                        <Form.Label>Tags</Form.Label>
                        <ReactTagInput
                            tags={wsCxt.wsDoc.tags}
                            placeholder="Type and press enter"
                            maxTags={10}
                            onChange={onChangeTags}
                            validator={(value) => {
                                return !wsCxt.wsDoc.tags.includes(value.trim());
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Alert show={showAlert} variant={alertVariant} onClose={() => setShowAlert(false)}>
                            {alertMessage}
                        </Alert>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide} variant="secondary">Cancel</Button>
                    <Button type="submit">{isNew ? 'Create' : 'Save'}</Button>
                </Modal.Footer>
            </Form>
        </Modal >

    );
}

export default EditWorkspaceModal;