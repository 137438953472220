
import React from 'react';
import { MoreVertical } from "react-feather";
import './CustomDropdownToggle.scss';

export const CustomDropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <span className="icon-wrapper-circle"><MoreVertical size={20} /></span>
    </a>
));