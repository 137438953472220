import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useStudio } from 'hooks/use-studio';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import FieldProps from './FieldProps';
import Plumber from 'hooks/plumber';
import Dropdown from 'react-bootstrap/Dropdown';
import { CustomDropdownToggle } from "components/CustomDropdownToggle";
import { Save, Copy, Trash2, Settings, ZoomOut, ZoomIn, RefreshCw } from "react-feather";


import './Studio.scss';

function Studio(props) {

  let history = useHistory();
  const [title, setTitle] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertVariant, setAlertVariant] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const studioCxt = useStudio();

  const qstnrId = props.match.params.qstnrId;

  const validateCanvasState = () => {
    let { fields, nodes, connections } = studioCxt.getCanvasStateAndFields();
    let errors = [];
    fields.forEach(fld => {
      if (!fld.title.trim()) {
        errors.push('Title Missing:' + fld.id);
      }
    })

    console.log(errors);
    //Move the validation code to plumber
    //loop through all fields
    //each field should have a title
    //if field has branches, each branch should have a connection to a field id
    //if field does not have branches, it should be connected to a field id
    //if field has choices and branch on chocies, each choice should have a connection to a field id
    //if field has choices and branch on chocies, each choice should have a connection to a field id
    //any choice should not be empty
  }

  const onClickSave = e => {
    studioCxt.setFieldActive(null);
    var saveResult = Plumber.validateCanvasState();
    if (!saveResult.result) {
      var alertMessage = saveResult.generalErrors ? saveResult.generalErrors :
        'Could not save changes. Please click on the fields displayed in red to review and fix the errors.';
      setAlertVariant('danger');
      setAlertMessage(alertMessage);
      setShowAlert(true);
    } else {
      studioCxt.saveCanvasState();
      //studioCxt.saveFlow();
      studioCxt.saveQstnrDoc()
        .then(() => {
          setAlertVariant('success');
          setAlertMessage('Changes saved successfully');
          setShowAlert(true);
        })
        .catch((err) => {
          setAlertVariant('danger');
          setAlertMessage(err.code + ' ' + err.message);
          setShowAlert(true);
        })
    }
  }

  const onClickSettings = e => {
    //confirm save changes
    Plumber.reset();
    const qstnrId = props.match.params.qstnrId;
    history.push(`/qstnairs/${qstnrId}/settings`);
  }

  useEffect(() => {
    if (showAlert) {
      window.setTimeout(() => setShowAlert(false), 4000);
    }
  }, [showAlert]);

  useEffect(() => {

    function onInit() {
      console.log('initing...');
      Plumber.initJSPlumb(studioCxt);
      Plumber.fields = [];
      const qstnrId = props.match.params.qstnrId;
      studioCxt.loadQstnrDoc(qstnrId)
        .then((doc) => {
          if (doc.exists) {
            let data = doc.data();
            console.log(data);
            setTitle(data.title);
            if (data.fields.length > 0) {
              Plumber.fields = data.fields;
              Plumber.setCanvasState(data.nodes, data.connections);
              setAlertVariant('success');
              setAlertMessage('Document loaded successfully');
              setShowAlert(true);
            }
            else {
              console.log("no fields defined yet...");
            }
          } else {
            setAlertVariant('danger');
            setAlertMessage('Document does not exist');
            setShowAlert(true);
          }
        })
        .catch((err) => {
          setAlertVariant('danger');
          setAlertMessage(err.code + ' ' + err.message);
          setShowAlert(true);
        })

      console.log('inited...');
    }

    onInit();
  }, []);

  const onChangeCheckbox = (e) => {
    console.log(e.target.checked);
  }

  return (
    <>
      <div className="header">
        <div className="container-fluid">
          <div className="header-body">
            {/* <div className="row text-center">
                            <div className="col-10 offset-1">
                                <button onClick={onClickEditFields} className="btn btn-sm btn-link navi">Edit Questions</button>
                                <button onClick={onClickPreview} className="btn btn-sm btn-link mr-2 navi">Preview QstnAir</button>
                                <button onClick={onClickPublish} className="btn btn-sm btn-link navi">Publish QstnAir</button>
                            </div>
                        </div> */}
            <div className="row align-items-end">
              <div className="col-2">
                <h6>QstnAir - {title}</h6>
                <h3 className="header-title">Edit Questions</h3>
              </div>
              <div className="col-8">
                <Alert show={showAlert} variant={alertVariant} onClose={() => setShowAlert(false)}>
                  {Array.isArray(alertMessage) ? alertMessage.map(item => <>{item}<br></br></>) : alertMessage}
                </Alert>
              </div>
              <div className="col-2">
                <div className="float-right m-1 d-flex">
                  <Button onClick={onClickSave} className="mr-2"><Save /> Save Changes</Button>
                  <Dropdown className="dd-primary">
                    <Dropdown.Toggle as={CustomDropdownToggle} />
                    <Dropdown.Menu size="sm" title="" className="custom-dd-menu">
                      <Dropdown.Item onClick={onClickSettings}><Settings size={16} /> Settings</Dropdown.Item>
                      <Dropdown.Item><Copy size={16} /> Copy To New</Dropdown.Item>
                      <Dropdown.Item><Trash2 size={16} /> Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid content-row">
        <Row className="row m-2">
          <Col sm={1} id="field-types">
            <div className="kanban-category">
              {studioCxt.fieldTypes.map((fieldType, i) =>
                <div className="kanban-item drag-clone" key={i}>
                  <div className={"card card-sm mb-0 draggable d-flex field " + fieldType.type} data-type={fieldType.type}>
                    <div className="card-body align-items-center d-flex ml-1">
                      <div className="row">
                        <div className="col-auto text-center">
                          <span className="text-center card-text">{fieldType.title}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col sm={8} className="canvas-wrapper px-0">
            <div id="controls" className="panzoom-exclude btn-group btn-group-sm float-right">
              <a onClick={Plumber.pzZoomOut} className="btn btn-secondary btn-sm"><ZoomOut size={20} /></a>
              <span id="pz-scale" className="btn btn-secondary btn-sm">100%</span>
              <a onClick={Plumber.pzZoomIn} className="btn btn-secondary btn-sm"><ZoomIn size={20} /></a>
              <a onClick={Plumber.pzReset} className="btn btn-secondary btn-sm"><RefreshCw size={20} /></a>
            </div>
            <div id="flow-canvas"></div>
          </Col>
          <Col sm={3}>
            {studioCxt.activeField &&
              < div id="field-props" className="card">
                <div className="card-header">
                  <h4 className="card-header-title">Question Details</h4>
                </div>
                <div className="card-body">
                  <FieldProps qstnrId={qstnrId} />
                </div>
              </div>
            }
          </Col>
        </Row >
      </div>
    </>
  );

}

export default Studio;