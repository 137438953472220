// Hook (use-auth.js)
import React, { useState, useEffect, useContext, createContext } from "react";
import { useFirebase } from 'hooks/use-firebase';

import { useHistory } from "react-router-dom";
import useSessionStorage from "hooks/session-storage.js";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {

  const fireContext = useFirebase();
  const { firebaseRef, fireDb, fireAuth } = fireContext;
  const [isPending, setIsPending] = useState(true);
  const [user, setUser] = useState(null);
  const [isManual, setIsManual] = useState(false);
  const [userProfile, setUserProfile] = useSessionStorage("ss_up", "");

  let history = useHistory();
  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = (email, password) => {
    setIsManual(true);
    return fireAuth
      .signInWithEmailAndPassword(email, password)
      .then(response => {
        //setUser(response.user);
        return response.user;
      })
  };

  const signup = (name, email, password, plan) => {
    setIsManual(true);
    return new Promise((resolve, reject) => {
        fireAuth
        .createUserWithEmailAndPassword(email, password)
        .then(response => {
          fireDb.collection("organizations").add({
            name: name,
            role_owners: [response.user.uid],
            role_editors: [],
            plan: plan || 'Basic',
            //created: firebaseRef.firestore.Timestamp.now(),
            created: firebaseRef.firestore.FieldValue.serverTimestamp()
          })
            .then(orgRef => {
              console.log("org created:" + orgRef.id);
              fireDb.collection("workspaces").add({
                name: "First Workspace",
                description: "My super cool workspace",
                tags: [],
                orgId: orgRef.id,
                role_any: [response.user.uid],
                role_owners: [response.user.uid],
                role_editors: [],
                role_readers: [],
                created: firebaseRef.firestore.FieldValue.serverTimestamp(),
                updated: firebaseRef.firestore.FieldValue.serverTimestamp(),
              })
                .then(wsRef => {
                  console.log("ws created:" + wsRef.id);
                  fireDb.collection("users").doc(response.user.uid).set({
                    name: name,
                    email: email,
                    orgs: [orgRef.id],
                    currentOrg: orgRef.id,
                    //currentWS: wsRef.id,
                    //created: firebaseRef.firestore.Timestamp.now()
                    created: firebaseRef.firestore.FieldValue.serverTimestamp()
                  })
                    .then(() => {
                      setUser(response.user);
                      resolve({ user: response.user, wsid: wsRef.id });
                    })
                    .catch(reject)
                })
                .catch(reject)
            })
            .catch(reject)
        })
        .catch(reject)
    });
  };

  const signout = () => {
    return fireAuth
      .signOut()
      .then(() => {
        history.push("/");
        setUser(false);
      });
  };

  const sendPasswordResetEmail = email => {
    return fireAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return fireAuth
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  const getUserProfile = (user) => {
    return new Promise((resolve, reject) => {
      fireDb.collection("users").doc(user.uid).get().then(function (doc) {
        if (doc.exists) {
          var updata = doc.data();
          setUserProfile(JSON.stringify(updata));
          resolve(updata);
        } else {
          reject("No user profile found!");
        }
        resolve(updata);
      }).catch(function (error) {
        console.log("Error fetching user profile:", error);
        reject(error);
      });
    });
  }

  // const storeUserProfile = (user) => {
  //   return new Promise((resolve, reject) => {
  //     db.collection("users").doc(user.uid).get().then(function (doc) {
  //       if (doc.exists) {
  //         var updata = doc.data();
  //         setCurrentAcct(updata.currentAcct);
  //         setCurrentWS(updata.currentWS);
  //         setUserProfile(window.btoa(JSON.stringify(updata)));
  //       } else {
  //         console.log("No user profile found!");
  //       }
  //       resolve(updata);
  //     }).catch(function (error) {
  //       console.log("Error fetching user profile:", error);
  //       reject(error);
  //     });
  //   });
  // }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = fireAuth.onAuthStateChanged(usr => {
      console.log("auth pending resolved");
      setIsPending(false);
      if (usr) {
        getUserProfile(usr)
          .then(up => {
            setUser(usr);
            if (!isManual) {
              if (history.location.pathname === '/login') {
                history.push('/workspaces');
              }
            }
          })
      } else {
        setUser(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [isManual]);

  // Return the user object and auth methods
  return {
    isPending,
    user,
    userProfile,
    getUserProfile,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset
  };
}