import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Settings } from "react-feather";
import InviteUserModal from './InviteUserModal';
import { useUsers } from 'hooks/use-users.js';

function Users() {
    const usersCxt = useUsers();

    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [modalKey, setModalKey] = useState(0);
    const [data, setData] = useState(null);

    const getArrFromQS = (querySnapshot) => {
        let arr = [];
        querySnapshot.forEach(function (doc) {
            var document = doc.data();
            document.id = doc.id;
            arr.push(document);
        });
        return arr;
    }

    const onUserInvited = () => {
        setIsLoading(true);
        usersCxt.getInvites()
            .then(function (qsInvites) {
                var invites = getArrFromQS(qsInvites);
                setData({ ...data, invites });
                usersCxt.resetUser();
                setIsLoading(false);
                console.log("invites reloaded");
            });
    }

    useEffect(() => {

        function onInit() {
            setIsLoading(true);
            usersCxt.getUsers()
                .then(function (qsUsers) {
                    var users = getArrFromQS(qsUsers);
                    usersCxt.getInvites()
                        .then(function (qsInvites) {
                            var invites = getArrFromQS(qsInvites);
                            setData({ ...data, users, invites });
                            setIsLoading(false);
                            console.log("users & invites loaded");
                        });
                    // let arr = [];
                    // querySnapshot.forEach(function (doc) {
                    //     var user = doc.data();
                    //     user.id = doc.id;
                    //     arr.push(user);
                    // });
                    // setData([...arr]);
                })
                .catch(function (err) {
                    setIsLoading(false);
                    console.log("Error fetching users: " + err);
                });
        }

        onInit();
    }, []);

    return (
        <>
            <InviteUserModal animation={false}
                backdrop="static"
                keyboard={false}
                show={showModal}
                onHide={(inviteSuccess) => {
                    if (inviteSuccess) {
                        onUserInvited();
                    }
                    setModalKey(modalKey + 1);
                    setShowModal(false)
                }}
                key={modalKey}
            />

            <div className="header">
                <div className="container-fluid">
                    <div className="header-body">
                        <div className="row align-items-end">
                            <div className="col">
                                <h6 className="header-pretitle">Overview</h6>
                                <h1 className="header-title">Users</h1>
                            </div>
                            <div className="col-auto">
                                <Button variant="primary" onClick={() => setShowModal(true)}>Invite User</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="table-responsive mb-0" >

                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '5%' }}>#</th>
                                    <th>Email</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && data.users.map((user, i) =>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{user.email}</td>
                                            <td><a href="#" onClick={console.log}>
                                                <Settings size={16} />
                                            </a></td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>

                    </div>
                </div>

                <div className="row">
                    <h3>Pending Invites</h3>
                </div>
                <div className="row">
                    <div className="table-responsive mb-0" >

                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th style={{ 'width': '5%' }}>#</th>
                                    <th>Email</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && data.invites.map((user, i) =>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{user.email} / {user.id}</td>
                                            <td><a href="#" onClick={console.log}>
                                                <Settings size={16} />
                                            </a></td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>

                    </div>
                </div>
            </div>
        </>
    );
}
export default Users;