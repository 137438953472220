import React, { useEffect } from 'react';
import { Route, Switch } from "react-router-dom";

import useSessionStorage from "hooks/session-storage.js";
import { ProvideUsers } from "hooks/use-users.js";

import TopNav from './TopNav';
import Workspaces from "views/Private/Workspaces";
import QstnAirs from "views/Private/QstnAirs";
import StudioHOC from "views/Private/Studio";
import Settings from "views/Private/Studio/Settings";
import Preview from "views/Private/Studio/Preview";
import Users from "views/Private/Users/Users";
import Invitation from "views/Private/User/Invitation";

function PrivateLayout(props) {
    const [gradients, setGradients] = useSessionStorage("ss_grdnts", "");
    useEffect(() => {
        const loadGradientsJson = () => {
            if (!gradients) {
                console.log('fetching...');
                fetch('/assets/js/gradients.json')
                    .then(function (response) {
                        console.log(response)
                        return response.json();
                    })
                    .then(function (jsonData) {
                        console.log(jsonData);
                        setGradients(jsonData);
                    });
            } else {
                console.log('already fetched.')
            }
        }
        loadGradientsJson();
    }, []);


    return (
        <React.Fragment>
            <div className="main-content" id="main">
                {<TopNav />}
                <Switch>
                    <Route exact path="/workspaces" component={Workspaces} />
                    <Route exact path="/workspaces/:workspaceId/users" render={() => <ProvideUsers><Users /></ProvideUsers>} />
                    <Route exact path="/workspaces/:workspaceId/qstnairs" component={QstnAirs} />
                    <Route exact path="/qstnairs/:qstnrId/studio" component={StudioHOC} />
                    <Route exact path="/qstnairs/:qstnrId/settings" component={Settings} />
                    <Route exact path="/qstnairs/:qstnrId/preview" component={Preview} />

                    <Route path="/qstnairs/:qstnrId/responses" component={Responses} />
                    <Route path="/qstnairs/:qstnrId/reports" component={Reports} />

                    <Route path="/organization" component={Organization} />
                    <Route path="/user/invites/:orgId/:inviteId" component={Invitation} />
                    <Route component={NoMatch} />
                </Switch>
            </div>
        </React.Fragment>
    )
}

function NoMatch({ location }) {
    return (
        <div>
            <h3>
                No match for <code>{location.pathname}</code>
            </h3>
        </div>
    );
}

function Workspace() {
    return (
        <div>
            <h2>Workspace Home</h2>
        </div>
    );
}

function Dashboard() {
    return (
        <div>
            <h2>Dashboard</h2>
        </div>
    );
}

function Responses() {
    return (
        <div>
            <h2>Responses</h2>
        </div>
    );
}

function Reports() {
    return (
        <div>
            <h2>Reports</h2>
        </div>
    );
}

// function Users() {
//     return (
//         <div>
//             <h2>Users</h2>
//         </div>
//     );
// }

function Teams() {
    return (
        <div>
            <h2>Teams</h2>
        </div>
    );
}

function Organization() {
    return (
        <div>
            <h2>Organization</h2>
        </div>
    );
}

//const PrivateLayout = withRouter(PrivateLayoutLOC);
export default PrivateLayout;