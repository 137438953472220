import React from 'react';
import './Spinner.scss';

function Spinner() {
    return (
        <div className="spinner spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    );
}

export default Spinner;