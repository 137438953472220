import React, { useState, createContext, useContext, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// fallback config when /__/ reserved url (or proxy) is unavailable
// const firebaseConfig = {
//   apiKey: "AIzaSyD1wcmpxebeV-p6YCdVT6SVA3nUSJ0_iKU",
//   authDomain: "qstnair-admin-dev.firebaseapp.com",
//   projectId: "qstnair-admin-dev",
//   storageBucket: "qstnair-admin-dev.appspot.com",
//   messagingSenderId: "20172417822",
//   appId: "1:20172417822:web:ff9652248089bc2907f70f"
// };

const firebaseContext = createContext();

export function ProvideFirebase({ children }) {
  const [firebaseRef, setFirebaseRef] = useState();
  const [fireDb, setFireDb] = useState();
  const [fireAuth, setFireAuth] = useState();
  const [fireStorage, setFireStorage] = useState();
  const [fireLoaded, setFireLoaded] = useState(false);

  useEffect(() => {
    // Initialize Firebase
    // This checks if the app is already initialized
    console.log('initing firebase.....');
    if (!firebaseRef && !firebase.apps.length) {

      // firebase.initializeApp(firebaseConfig);
      // setFirebaseRef(firebase);
      // setFireDb(firebase.firestore());
      // setFireAuth(firebase.auth());
      // setFireStorage(firebase.storage());
      // setFireLoaded(true);
      // console.log('inited fyrbase');

      fetch('/__/firebase/init.json').then(async response => {
        firebase.initializeApp(await response.json());
        setFirebaseRef(firebase);
        setFireDb(firebase.firestore());
        setFireAuth(firebase.auth());
        setFireStorage(firebase.storage());
        setFireLoaded(true);
        console.log('inited firebase');        
      });
      // fetch('/__/firebase/init.json').then(async response => {
      //   firebase.initializeApp(await response.json());
      //   setFyrbase(firebase);
      //   setDb(firebase.firestore());
      //   setAuth(firebase.auth());
      //   setStorage(firebase.storage());
      //   console.log('inited fyrbase');
      // });

    }
  }, [])

  return (
    <firebaseContext.Provider value={{ firebaseRef, fireDb, fireAuth, fireStorage, fireLoaded }}>
      {children}
    </firebaseContext.Provider>
  )
}

export const useFirebase = () => useContext(firebaseContext);

