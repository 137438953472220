//https://firebase.google.com/docs/reference/js/firebase.storage.UploadTask

import React, { useContext, createContext } from "react";
import { useFirebase } from 'hooks/use-firebase';

const fireutilsContext = createContext();

export function ProvideFireUtils({ children }) {
    const utils = useProvideFireUtils();
    return <fireutilsContext.Provider value={utils}>{children}</fireutilsContext.Provider>;
}

export const useFireUtils = () => {
    return useContext(fireutilsContext);
};

function useProvideFireUtils() {
    const fireContext = useFirebase();
    const { firebaseRef, fireStorage } = fireContext;

    function uploadFileToFirebase(qstnrId, fieldId, file, metadata) {

        var storageRef = fireStorage.ref();
        const filePath = `images/qstnairs/${qstnrId}/${fieldId}/${file.name}`;
        var uploadTask = storageRef.child(filePath).put(file, metadata);

        return new Promise((resolve, reject) => {

            var nextFn = function (snapshot) {
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebaseRef.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebaseRef.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                    default:
                        console.log('In Default');
                }
            };

            var errorFn = function (error) {
                switch (error.code) {
                    case 'storage/unauthorized':
                        console.log('unauthorized');
                        reject(error.code + ": " + error.message);
                        break;
                    case 'storage/canceled':
                        console.log('canceled');
                        reject(error.code + ": " + error.message);
                        break;
                    case 'storage/unknown':
                        console.log('unknown');
                        reject(error.code + ": " + error.message);
                        break;
                    default:
                        console.log('In Default');
                }
            };

            var completeFn = function () {
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    console.log('File available at', downloadURL);
                    resolve(downloadURL);
                });
            };

            uploadTask.on(firebaseRef.storage.TaskEvent.STATE_CHANGED, {
                'next': nextFn,
                'error': errorFn,
                'complete': completeFn
            });
        })

    }

    function deleteFile(pathToFile, fileName) {
        const pathRef = fireStorage.ref(pathToFile);
        const fileRef = pathRef.child(fileName);
        fileRef.delete().then(function () {
            console.log(fileName + ' deleted successfully');
        }).catch(function (error) {
            console.log('error deleting ' + fileName);
            console.log(error);
        });
    }

    function deleteFolder(folderPath) {
        console.log(folderPath);
        const pathRef = fireStorage.ref(folderPath);
        pathRef.listAll()
            .then(dir => {
                dir.items.forEach(fileRef => {
                    deleteFile(pathRef.fullPath, fileRef.name);
                });
                dir.prefixes.forEach(folderRef => {
                    deleteFolder(folderRef.fullPath);
                })
            })
            .catch(error => {
                console.log(error);
            });
    }

    return {
        uploadFileToFirebase,
        deleteFile,
        deleteFolder
    }
}

