import React, { useState, useContext, createContext } from "react";
import { useFirebase } from 'hooks/use-firebase';
import Utils from 'hooks/utils';

const qstnrContext = createContext();

export function ProvideQstnr({ children }) {
    const qstnr = useProvideQstnr();
    return <qstnrContext.Provider value={qstnr}>{children}</qstnrContext.Provider>;
}

export const useQstnr = () => {
    return useContext(qstnrContext);
};

function useProvideQstnr() {

    const fireContext = useFirebase();
    const { firebaseRef, fireDb } = fireContext;   
    var browserLang = window.navigator.userLanguage || window.navigator.language;
    if (browserLang) {
        browserLang = browserLang.substring(0, 2);
    }
    let settingsObj = {
        fields: [],
        nodes: [],
        connections: [],
        title: "",
        description: null,
        defaultLang: browserLang || 'en',
        isMultilingual: false,
        languages: null,
        formEditors: "account",
        docCreators: "signedin",
        docReaders: "me",
        notifyEditors: false,
        notifyRespondent: false,
        translations: {},
        isCustomBG: false,
        bgType: "color",
        bgObject: {
            hex: "#4dd0e1",
            rgb: {
                r: "77",
                g: "208",
                b: "225",
                a: "1"
            }
        }
    }
    const [qstnrDocRef, setQstnrDocRef] = useState(null);
    const [settings, setSettings] = useState({ ...settingsObj });

    function setSettingsProp(obj) {
        var key = Object.keys(obj)[0];
        console.log(key);
        var value = obj[key];
        setSettings(prevState => {
            return { ...prevState, [key]: value }
        });
    }

    function setSettingsBGProps(bgType, bgObject) {
        setSettings(prevState => {
            return { ...prevState, bgType, bgObject }
        });        
    }

    function setTranslationLanguages(arr) {
        const translations = Object.assign({}, settings.translations);
        console.log(translations);
        if (!translations) {
            translations = {};
        }
        else {
            Object.keys(translations).forEach(key => {
                if (!arr.includes(key)) {
                    console.log('deleting translation for lang: ' + key);
                    delete translations[key];
                }
            });
        }
        if (arr && arr.length > 0) {
            arr.forEach(item => {
                if (!translations[item]) {
                    translations[item] = {};
                }
            })
        }
        setSettingsProp({ translations });
    }

    function setTranslationKeyValue(lang, id, val) {
        setSettings(prevState => {
            return {
                ...prevState, translations: {
                    ...prevState.translations, [lang]: {
                        ...prevState.translations[lang], [id]: val
                    }
                }
            }
        });
    }

    function resetQstnrDoc() {
        setSettings({ ...settingsObj });
    }

    function createQstnrDoc(workspaceId) {
        return fireDb.collection("qstnairs").add({
            ...settings,
            workspaceId,
            created: firebaseRef.firestore.FieldValue.serverTimestamp()
        })
    }

    function loadQstnrDoc(docId) {
        let docRef = fireDb.collection("qstnairs").doc(docId);
        setQstnrDocRef(docRef);

        docRef.get().then(function (doc) {
            if (doc.exists) {
                console.log("Document data:", doc.data());
                var docData = doc.data();

                var fieldIds = Utils.pluck(docData.fields, 'id');
                var connTargets = Utils.pluck(docData.connections, 'tgt');
                console.log(fieldIds);
                console.log(connTargets);
                var notTargets = [];
                fieldIds.forEach(tgt => {
                    if (!connTargets.includes(tgt)) {
                        notTargets.push(tgt);
                    }
                })
                console.log(notTargets);
                // docData.fields.forEach(fld=>{
                //     console.log('fld:'+fld.id);
                // });
                // docData.connections.forEach(conn=>{
                //     console.log('tgt:'+conn.tgt);
                // });                
                setSettings(docData);
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }

    function saveQstnrDoc() {
        return qstnrDocRef.update({
            ...settings,
            udpated: firebaseRef.firestore.FieldValue.serverTimestamp()
        });
    }

    return {
        settings,
        setSettingsProp,
        setSettingsBGProps,
        setTranslationLanguages,
        setTranslationKeyValue,
        createQstnrDoc,
        loadQstnrDoc,
        saveQstnrDoc,
        resetQstnrDoc,
    }
}