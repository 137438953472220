import React, { useState, useContext, createContext } from "react";
import { useFirebase } from 'hooks/use-firebase';
import { useAuth } from 'hooks/use-auth';

const usersContext = createContext();

export function ProvideUsers({ children }) {
    const users = useProvideUsers();
    return <usersContext.Provider value={users}>{children}</usersContext.Provider>;
}

export const useUsers = () => {
    return useContext(usersContext);
};

function useProvideUsers() {

    const fireContext = useFirebase();
    const { firebaseRef, fireDb, fireAuth } = fireContext;
    const auth = useAuth();

    let userObj = {
        email: null,
        inviteStatus: 'pending',
    }
    const [user, setUser] = useState({ ...userObj });

    const resetUser = () => {
        setUser({ ...userObj });
    }

    function setUserProp(obj) {
        var key = Object.keys(obj)[0];
        var value = obj[key];
        setUser(prevState => {
            return { ...prevState, [key]: value }
        });
    }

    // const getWorkspaces = () => {
    //     const userProfile = JSON.parse(auth.userProfile);
    //     const orgId = userProfile.currentOrg;
    //     return db.collection("workspaces").where("orgId", "in", "owners")
    //         .get()
    //         .then(function (querySnapshot) {
    //             querySnapshot.forEach(function (doc) {
    //                 console.log(doc.id, " => ", doc.data());
    //             });
    //         })
    //         .catch(function (error) {
    //             console.log("Error getting documents: ", error);
    //         });
    // }

    // const getUsers = () => {
    //     const userProfile = JSON.parse(auth.userProfile);
    //     const orgId = userProfile.currentOrg;
    //     return db.collection("accounts/" + acctId + "/users").get();
    // }

    const getInvites = () => {
        const userProfile = JSON.parse(auth.userProfile);
        const orgId = userProfile.currentOrg;
        return fireDb.collection("organizations/" + orgId + "/invites").get();
    }

    const saveInvite = () => {
        var uid = fireAuth.currentUser.uid;
        return fireDb.collection("users").doc(uid).get().then(function (doc) {
            if (doc.exists) {
                var docData = doc.data();
                var currentOrg = docData.currentOrg;
                if (currentOrg) {
                    console.log('currentOrg' + currentOrg);
                    return fireDb.collection("organizations/" + currentOrg + "/invites").add(user);
                }
            } else {
                console.log("No such document!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }

    const sendInvite = (email) => {
        //todo implement this function
        // if user does not exist link should be /signup?to=/user/invites/{inviteId}
        // if user exists /login?to=/user/invites/{inviteId}
        var sendInvite = firebaseRef.functions().httpsCallable('sendEmailInvitation');
        sendInvite({ email }).then(function (result) {
            var result = result.data.text;
        }).catch(function (error) {
            var code = error.code;
            var message = error.message;
            var details = error.details;
        });
    }

    const updateUser = (user) => fireDb.collection("users").doc(user.id).update(
        { ...user, updated: firebaseRef.firestore.FieldValue.serverTimestamp() }
    );

    const deleteUser = (userId) => fireDb.collection("users").doc(userId).delete();

    //todo pra - remove user from org - create a cloud function to 
    //1. remove the user from all workspaces
    //2. remove the orgId from the user profile's orgs array

    const getInvite = (inviteId) => fireDb.collectionGroup('invites')
        .where('songName', '==', 'X')
        .get()

    return {
        user,
        resetUser,
        setUserProp,
        //getUsers,
        getInvites,
        //getWorkspaces,
        saveInvite,
        sendInvite,
        updateUser,
        deleteUser
    }
}