import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { PlusCircle, ChevronRight } from 'react-feather';

import { useAuth } from 'hooks/use-auth';
import { ProvideWS, useWS } from "hooks/use-ws.js";
import { CustomDropdownToggle } from "components/CustomDropdownToggle";
import EditWorkspaceModal from './EditWorkspaceModal';

import './Workspaces.scss';

function Workspaces(props) {
  const auth = useAuth();
  const wsCxt = useWS();
  const [modalKey, setModalKey] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleShowEdit = (ws) => {
    wsCxt.setWsDoc(ws);
    setShowModal(true);
  }
  const handleShowDelete = (ws) => {
    wsCxt.setWsDoc(ws);
    setShowDeleteModal(true);
  }
  const handleCloseDelete = () => setShowDeleteModal(false);
  const onConfirmDeleteWS = () => {
    wsCxt.deleteWorkspace().then(function () {
      setShowDeleteModal(false);
    })
  }

  useEffect(() => {
    const listWorkspaces = () => {
      setIsLoading(true);
      wsCxt.getWorkspaces().then(() => {
        setIsLoading(false);
      }).catch(function (err) {
        setIsLoading(false);
        console.log("Error fetching workspaces: " + err);
      })
    }

    function onInit() {
      listWorkspaces();
    }
    onInit();
  }, []);

  const WSDropdown = (props) => {
    const userId = auth.user.uid;
    const showEdit = props.ws.role_owners.includes(userId); //|| ws.role_editors.includes(userId); //todo pra
    const showDelete = props.ws.role_owners.includes(userId);
    if (showEdit || showDelete) {
      return <Dropdown className="text-right m-2">
        {/* <Dropdown.Toggle variant="outline-primary" className="dropdown-basic"> */}
        <Dropdown.Toggle as={CustomDropdownToggle} />
        <Dropdown.Menu>
          {showEdit && <Dropdown.Item onClick={() => handleShowEdit(props.ws)}>Edit</Dropdown.Item>}
          {showDelete && <Dropdown.Item onClick={() => handleShowDelete(props.ws)}>Delete</Dropdown.Item>}
        </Dropdown.Menu>
      </Dropdown>
    }
    return null;
  }

  return (
    <>
      <EditWorkspaceModal animation={false}
        backdrop="static"
        keyboard={false}
        show={showModal}
        onHide={() => {
          setModalKey(modalKey + 1);
          setShowModal(false);
        }}
        key={modalKey}
      />

      <Modal show={showDeleteModal} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>Close</Button>
          <Button variant="danger" onClick={onConfirmDeleteWS}>Delete</Button>
        </Modal.Footer>
      </Modal>

      <div className="header mx-4">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row align-items-end">
              <div className="col">
                {/* <h5 className="header-pretitle">Overview</h5> */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">Home</li>
                  </ol>
                </nav>
                <h1 className="header-title">Workspaces</h1>
              </div>
              <div className="col-auto">
                <Button variant="primary" onClick={() => setShowModal(true)}><PlusCircle /> Create New Workspace</Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content mx-4">
        <div className="container-fluid">
          <div className="row">
            {wsCxt.workspaces.map(ws =>
              <div className="col-12 col-md-4 col-xl-3" key={ws.id}>
                <div className="card bg-vibrant bg-soft-primary">
                  <WSDropdown ws={ws} />
                  <div className="card-body text-center">
                    <h2 className="card-title"><Link to={`/workspaces/${ws.id}/qstnairs`}>{ws.name}</Link></h2>
                    <p className="header-subtitle text-muted mb-3">
                      {ws.description}&nbsp;
                  </p>
                    <p className="card-text">
                      {ws.tags && ws.tags.map((tag, i) =>
                        <span className="badge badge-info mr-1" key={i}>
                          {tag}
                        </span>
                      )}
                      {!ws.tags.length && <span className="badge">&nbsp;</span>}
                    </p>
                  </div>
                  <div className="card-footer card-footer-boxed">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-auto">
                        <Link to={`/workspaces/${ws.id}/qstnairs`} className="btn btn-sm btn-outline-primary">QstnAirs <ChevronRight size={16} /></Link>
                      </div>
                      <div className="col-auto">
                        <Link to={`/workspaces/${ws.id}/users`} className="btn btn-sm btn-outline-primary">Users <ChevronRight size={16} /></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

function WorkspacesHOC(props) {
  return (
    <ProvideWS>
      <Workspaces {...props} />
    </ProvideWS>
  )
}


export default WorkspacesHOC;